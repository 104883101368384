import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Box,
    CircularProgress,
    Drawer,
    Fab,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    useTheme,
} from "@mui/material";
import { BooleanParam, JsonParam, StringParam, useQueryParam } from "use-query-params";
import {
    formatAccountingDecimal,
    formatCompactAmount,
    formatRatioToPercentage,
    formatTemplate,
    formatYearMonthShort,
    getCompactFormatter,
    getCurrencySymbol,
    getSymbol,
} from "../../util/formatter";
import AdvisorContainer from "../../layout/AdvisorContainer";
import { Stack, styled } from "@mui/system";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadReport, generateReportLink } from "../../util/report_util";
import { hasValue } from "../../util/util";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { getFormattedValue } from "../../components/chart/common";
import ReportCustomizationBar from "./ReportCustomizationBar";
import { isEmpty, isEqual } from "lodash";
import { isForecastingEnabled } from "../../util/feature_flag_util";
import { DayJSParam } from "../../util/url";
import { AppContext } from "../../AppRouter";
import { computeReport, getReport } from "../../util/client";
import { convertToISODate, getAllMonthsBetweenDates } from "../../util/time";

// used to render the dates
const SubHeaderTableCell = styled(TableCell)(({ colSpan, theme }) => ({
    colSpan: colSpan,
    fontSize: "14px",
    textAlign: "center",
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.grey["700"],
    color: "white",
    // Styling to stick subheader just below the header
    position: "sticky",
    top: "37px",
    zIndex: 1,
}));

// used to render the name of the block
const HeaderTableCell = styled(SubHeaderTableCell)(() => ({
    fontSize: "16px",
    borderRadius: "8px 8px 0 0",
    // Styling to stick header to the top of the table
    position: "sticky",
    top: 0,
    zIndex: 1,
}));

// used on first column to render the row names
const IndexTableCell = styled(TableCell)(({ color }) => ({
    fontStyle: "inherit",
    fontWeight: "inherit",
    textDecoration: "none",
    background: color || "inherit",
    // Styling to stick column to the left of the table
    position: "sticky",
    left: 0,
    zIndex: 1,
}));

// used to render all cells that hold values, including forecasting (with different color)
const StyledTableCell = styled(TableCell)(({ color }) => ({
    fontStyle: "inherit",
    fontWeight: "inherit",
    fontSize: "13px",
    textAlign: "right",
    whiteSpace: "nowrap",
    backgroundColor: color || "inherit",
}));

// used on "is_header" cells
const EmptyTableCell = styled(TableCell)(() => ({
    fontStyle: "inherit",
    fontWeight: "inherit",
    borderBottom: "none",
    backgroundColor: "inherit",
}));

// used between "actual", "actual-reference", "actual/reference", "reference" blocks
const SeparatorTableCell = styled(EmptyTableCell)(() => ({
    backgroundColor: "white",
}));

const ReadReport = () => {
    const { client, config, notify, user } = useContext(AppContext);
    const theme = useTheme();

    const { reportId } = useParams();

    const [hasReference, setHasReference] = useState(true);

    const [multiplier, setMultiplier] = useQueryParam("multiplier", StringParam, { enableBatching: true });
    const multiplierOptions = config.i18n.report.multiplier.options;

    const [hideInitialMonths, setHideInitialMonths] = useQueryParam("hideInitialMonths", BooleanParam, { enableBatching: true });
    const [hideEmptyRows, setHideEmptyRows] = useQueryParam("hideRows", BooleanParam, { enableBatching: true });
    const [reportResults, setReportResults] = useState(null);
    const [computingReport, setComputingReport] = useState(false);
    const [report, setReport] = useState();
    const [description, setDescription] = useState();

    const [scenarioOptions, setScenarioOptions] = useState();
    const [scenario, setScenario] = useQueryParam("scenario", JsonParam, { enableBatching: true });
    const [documentDimensions, setDocumentDimensions] = useState(null);
    const [filtersInDimensions, setFiltersInDimensions] = useQueryParam("filtersInDims", JsonParam, { enableBatching: true });
    const [filtersInParameters, setFiltersInParameters] = useQueryParam("filtersInParams", JsonParam, { enableBatching: true });
    const [filtersOutDimensions, setFiltersOutDimensions] = useQueryParam("filtersOutDims", JsonParam, { enableBatching: true });
    const [filtersOutParameters, setFiltersOutParameters] = useQueryParam("filtersOutParams", JsonParam, { enableBatching: true });
    const [reportDimensionsMetadata, setReportDimensionsMetadata] = useState(null);

    const historicalMin = config.time.historical.min;
    const historicalMax = config.time.historical.max;
    const [maxDate, setMaxDate] = useState(null);
    const [startDate, setStartDate] = useQueryParam("startDate", DayJSParam, { enableBatching: true });
    const [endDate, setEndDate] = useQueryParam("endDate", DayJSParam, { enableBatching: true });
    const [cutoffDate, setCutoffDate] = useQueryParam("cutoffDate", DayJSParam, { enableBatching: true });

    const [open, setOpen] = useState([]);
    const [visibility, setVisibility] = useState([]);

    const [loadingDownload, setLoadingDownload] = useState(false);

    const [isCustomizationDrawerOpen, setIsCustomizationDrawerOpen] = useState(true);
    const drawerWidth = 388;
    const renderDrawer = startDate && endDate && documentDimensions && filtersInDimensions && filtersInParameters && reportDimensionsMetadata
        && filtersOutDimensions && filtersOutParameters && (!isForecastingEnabled(config) || scenario);

    const issueDownloadReport = (title, reportId) => {
        setLoadingDownload(true);
        downloadReport(title, reportId, {
            scenario_id: scenario?.id,
            start_date: convertToISODate(startDate),
            end_date: convertToISODate(endDate),
            cutoff_date: isForecastingEnabled(cutoffDate) ? convertToISODate(cutoffDate) : null,
            ui_filters: {
                dimensions_filters_in: filtersInDimensions,
                parameters_filters_in: filtersInParameters,
                dimensions_filters_out: filtersOutDimensions,
                parameters_filters_out: filtersOutParameters,
            },
        },
        () => setLoadingDownload(false),
        () => {
            setLoadingDownload(false);
            notify.error("", "report.download");
        });
    };

    const hideRow = (row, index) => {
        return hideEmptyRows && isRowEmpty(row) && areChildrenRowsEmpty(row, index);
    };

    const isRowEmpty = (row) => {
        return (row.actual ? Object.values(row.actual?.dates).every(val => !val) && !row.actual?.ytd && !row.actual?.fy : true)
            && (row.reference ? Object.values(row.reference?.dates).every(val => !val) && !row.reference?.ytd && !row.reference?.fy : true)
            && (row.ar_diff ? Object.values(row.ar_diff?.dates).every(val => !val) && !row.ar_diff?.ytd && !row.ar_diff?.fy : true)
            && (row.ar_div ? Object.values(row.ar_div?.dates).every(val => !val) && !row.ar_div?.ytd && !row.ar_div?.fy : true);
    };

    const areChildrenRowsEmpty = (parentRow, parentIndex) => {
        if (isParentRow(parentIndex, parentRow.indentation)) {
            let indentation = parentRow.indentation + 1;

            // Iterate through all children rows of parentRow
            for (let i = parentIndex + 1; i < reportResults.results.length && indentation > parentRow.indentation; i++) {
                if (!isRowEmpty(reportResults.results[i])) {
                    return false;
                } else if (i + 1 < reportResults.results.length) {
                    // update indentation to be equal to the next children row
                    indentation = reportResults.results[i + 1].indentation;
                }
            }
        }

        return true;
    };

    const showColumn = (index) => {
        return !hideInitialMonths || index === dateColumnsActual.length - 1;
    };

    const toggleOpen = (index) => {
        const newOpen = [...open];
        const newVisibility = [...visibility];

        newOpen[index] = !newOpen[index];

        const isExpanding = !!newOpen[index];

        for (let i = index + 1; i <= reportResults.results.length - 1; i++) {
            if (reportResults.results[i].indentation <= reportResults.results[index].indentation) {
                break;
            }

            if (isExpanding) {
                if (newOpen[index] !== newOpen[i] && reportResults.results[i].indentation === reportResults.results[index].indentation + 1) {
                    newOpen[i] = false; // never open child contents. only display child row, with its children closed
                    newVisibility[i] = newOpen[index];
                } else {
                    newVisibility[i] = newOpen[i];
                }
            } else {
                newOpen[i] = false;
                newVisibility[i] = false;
            }
        }

        setOpen(newOpen);
        setVisibility(newVisibility);
    };

    const setIntersectingDocumentDimensions = (reportTemplateId) => {
        client.report.reportGetReportTemplateIntersectingDimensions(reportTemplateId)
            .then(dimensions => setDocumentDimensions(dimensions))
            .catch(error => notify.error(error, "report.fetch"));
    };

    const fetchMetadata = (dimensionFilters) => {
        const ids = Object.values(dimensionFilters).flat();
        client.dimension.dimensionMetadata(ids)
            .then(metadata => setReportDimensionsMetadata(metadata))
            .catch(error => notify.error(error, "report.fetch"));
    };

    const populateDimensionSelector = (reportTemplateId, dimensionsFiltersIn, parametersFiltersIn, dimensionsFiltersOut, parametersFiltersOut, urlOption) => {
        setIntersectingDocumentDimensions(reportTemplateId);

        // only populate dimensions coming from report, if the URL doesn't come with any.
        if (isEmpty(filtersInDimensions)) {
            setFiltersInDimensions(dimensionsFiltersIn, urlOption);
        }
        if (isEmpty(filtersInParameters)) {
            setFiltersInParameters(parametersFiltersIn, urlOption);
        }

        if (isEmpty(filtersOutDimensions)) {
            setFiltersOutDimensions(dimensionsFiltersOut, urlOption);
        }
        if (isEmpty(filtersOutParameters)) {
            setFiltersOutParameters(parametersFiltersOut, urlOption);
        }
    };

    const fetchReport = (reportId, scenarioOptions = []) => {
        getReport(client, reportId)
            .then((report) => {
                // replace URL to avoid pressing back and go through a state where there isn't parameters.
                const urlOption = "replaceIn";

                if (isEmpty(scenario)) {
                    setScenario(scenarioOptions?.find((el => el.id === report.scenario_id)) || {}, urlOption);
                }

                if (isEmpty(startDate)) {
                    setStartDate(report.start_date, urlOption); // Avoid using system's timezone, it will de-format the date
                }

                if (isEmpty(endDate)) {
                    setEndDate(report.end_date, urlOption); // Avoid using system's timezone, it will de-format the date
                }

                if (isEmpty(multiplier)) {
                    setMultiplier(report.multiplier, urlOption);
                }

                if (hideEmptyRows == null) {
                    setHideEmptyRows(report.hide_empty_rows, urlOption);
                }

                if (hideInitialMonths == null) {
                    setHideInitialMonths(report.hide_initial_months, urlOption);
                }

                if (isForecastingEnabled(config) && isEmpty(cutoffDate)) {
                    setCutoffDate(report.cutoff_date ? report.cutoff_date : config.time.forecasting ? config.time.forecasting.min : historicalMax);
                }

                populateDimensionSelector(report.report_template_id, report.ui_filters.dimensions, report.ui_filters.dimension_filters, urlOption);
                populateDimensionSelector(report.report_template_id, report.ui_filters.dimensions_filters_in, report.ui_filters.parameters_filters_in,
                    report.ui_filters.dimensions_filters_out, report.ui_filters.parameters_filters_out, urlOption);

                fetchMetadata({ ...filtersInParameters, ...filtersOutParameters, ...report.ui_filters.parameters_filters_in,
                    ...report.ui_filters.parameters_filters_out });

                fetchDescription(report.report_template_id);

                setMaxDate(isForecastingEnabled(config) ? config.time.forecasting.max : historicalMax);

                // setting the report ensures report calculation is only done, after getting the report first.
                setReport(report);
            })
            .catch((error) => {
                notify.error(error, "report.fetch");
            });
    };

    const fetchDescription = (reportTemplateId) => {
        client.report.reportGetReportTemplateDescription(reportTemplateId)
            .then((description) => {
                setDescription(description);
            })
            .catch((error) => {
                setDescription("");
                notify.error(error, "report.fetch");
            });
    };

    useEffect(() => {
        if (reportId) {
            // ensuring scenario matters only if FF is enabled
            if (isForecastingEnabled(config) && user.permissions?.FORECAST?.READ) {
                client.scenario.scenarioListScenarios()
                    .then((scenarios) => {
                        setScenarioOptions(scenarios.response);
                        fetchReport(reportId, scenarios.response);
                    })
                    .catch((error) => {
                        setScenarioOptions(null);
                        notify.error(error, "scenario.fetch");
                    });
            } else {
                fetchReport(reportId);
            }
        }
    }, [reportId]);

    useEffect(() => {
        // ensuring scenario matters only if FF is enabled.
        // report is important, to make sure we only compute after fetching the report first.
        // using the URL, all other parameters may be present first, without the report itself.
        if (reportId && report && startDate && endDate && filtersInDimensions && filtersInParameters && filtersOutDimensions && filtersOutParameters
            && (!isForecastingEnabled(config) || scenario)) {
            setComputingReport(true);
            computeReport(client, reportId, startDate, endDate, scenario?.id, isForecastingEnabled(config) && !isEmpty(scenario) ? cutoffDate : null, filtersInDimensions, filtersInParameters, filtersOutDimensions, filtersOutParameters)
                .then((reportResults) => {
                    setReportResults(reportResults);
                    setHasReference(reportResults.results.some(el => el.reference));
                    setComputingReport(false);
                })
                .catch((error) => {
                    setComputingReport(false);
                    notify.error(error, "report.compute");
                });
        }
    }, [reportId, report, startDate, endDate, scenario, filtersInParameters, filtersOutParameters, cutoffDate]);

    useEffect(() => {
        if (reportResults?.results.length !== open.length) {
            setOpen(Array(reportResults?.results.length).fill(false));
            setVisibility(Array(reportResults?.results.length).fill(false));
        }
    }, [reportResults]);

    const hasForecasting = () => {
        return isForecastingEnabled(config) && !isEmpty(scenario) && dateColumnsActual.some(el => el >= cutoffDate);
    };

    const isParentRow = (index, indentation) => {
        return reportResults.results[index + 1]?.indentation > 0 && indentation < reportResults.results[index + 1]?.indentation;
    };

    const getAllValuesByType = (types, block, index = -1, independent = false) => {
        const values = [0];

        if (reportResults) {
            for (let i = 0; i < reportResults.results?.length; i++) {
                // we don't need to push any values on a header row.
                if (reportResults.results[i].is_header) {
                    continue;
                }

                if ((!independent && !reportResults.results[i].independent) || (independent && i === index)) {
                    if (types.includes(reportResults.results[i]?.label_type_actual.y) && (block === "actual" || block === "reference")) {
                        values.push(...Object.values(reportResults.results[i].actual?.dates || []).filter(val => hasValue(val)));
                        values.push(...Object.values(reportResults.results[i]?.reference?.dates || []).filter(val => hasValue(val)));
                    }

                    if (types.includes(reportResults.results[i]?.label_type_ar_diff.y) && block === "ar_diff") {
                        values.push(...Object.values(reportResults.results[i]?.ar_diff?.dates || []).filter(val => hasValue(val)));
                        values.push(reportResults.results[i]?.ar_diff?.ytd || 0);
                        values.push(reportResults.results[i]?.ar_diff?.fy || 0);
                    }

                    if (types.includes(reportResults.results[i]?.label_type_ar_div.y) && block === "ar_div") {
                        values.push(...Object.values(reportResults.results[i]?.ar_div?.dates || []).filter(val => hasValue(val)));
                        values.push(reportResults.results[i]?.ar_div?.ytd || 0);
                        values.push(reportResults.results[i]?.ar_div?.fy || 0);
                    }
                }
            }
        }

        return values;
    };

    const getFormattedCellValue = (row, index, val, labelType, block) => {
        if (!hasValue(val)) {
            return "-";
        }

        let formatter;
        switch (labelType.y) {
            case "PERCENTAGE":
                formatter = formatRatioToPercentage.bind(null, val, config.locale, true, 0);
                break;
            case "AMOUNT":
                formatter = getCompactFormatter(
                    getAllValuesByType(["AMOUNT"], block, index, row.independent),
                    config.locale,
                    config.i18n,
                    false,
                    row.independent ? formatCompactAmount : formatAccountingDecimal,
                    !row.independent,
                    row.independent,
                    row.independent ? "AUTO" : multiplier);
                break;
            default:
                formatter = getCompactFormatter(
                    getAllValuesByType([labelType.y], block, index, row.independent),
                    config.locale,
                    config.i18n,
                    false,
                    formatAccountingDecimal);
                break;
        }

        return formatter(val);
    };

    if (!report) {
        return null;
    }

    const title = report.name;

    const subtitle = reportResults && reportResults.subtitle && formatTemplate(
        reportResults.subtitle,
        reportResults.metadata,
        config.locale,
        config.i18n);

    const otherColumns = ["YTD", "FY"];
    const dateColumnsActual = getAllMonthsBetweenDates(startDate, endDate);

    let dateColumnsReference = dateColumnsActual;
    if (reportResults) {
        const offset = reportResults.results[0].offset;
        const start = startDate.add(offset, "month");
        const end = endDate.add(offset, "month");

        dateColumnsReference = getAllMonthsBetweenDates(start, end);
    }

    const reportParameters = {
        DATE: [startDate, endDate],
    };

    if (scenario) {
        reportParameters.SCENARIO = scenario;
    }

    return (
        <>
            <AdvisorContainer
                title={title}
                toolTipInfo={description}
                subtitle={subtitle}
                actions={(
                    <Fab
                        aria-label="download"
                        onClick={() => issueDownloadReport(report.name, reportId)}
                    >
                        {loadingDownload
                            ? <CircularProgress size={10} />
                            : <DownloadIcon sx={{ fontSize: "30px", margin: "auto" }} />}
                    </Fab>
                )}
                loading={!reportResults || computingReport || !renderDrawer}
                loadingLabel={config.i18n.report.read.loading}
                minWidth={0}
                maxWidth="xxl"
                maxHeight="calc(100vh - 200px)" // Ensure the height of this page is the height of the screen
            >
                {reportResults
                    ? (
                        <Box marginTop={1} maxHeight="inherit">
                            <TableContainer sx={{ overflow: "auto", maxHeight: "inherit" }}>
                                <Table size="small" style={{ borderCollapse: "separate" }} data-cy="report">
                                    <TableHead>
                                        <TableRow style={{ height: "32px" }}>
                                            <TableCell
                                                colSpan={1}
                                                rowSpan={2}
                                                style={{
                                                    // Cell has custom styling since it needs to stick to the top left corner of the table
                                                    backgroundColor: "white",
                                                    position: "sticky",
                                                    top: 0,
                                                    left: 0,
                                                    zIndex: 2,
                                                    fontSize: "16px",
                                                    textAlign: "center",
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                {getCurrencySymbol(config.locale) + " "
                                                + getSymbol(getAllValuesByType(["AMOUNT"], "actual"), config.locale, multiplier)}
                                            </TableCell>
                                            <HeaderTableCell
                                                colSpan={hideInitialMonths ? 3 : dateColumnsActual.length + otherColumns.length}
                                            >
                                                {hasForecasting() ? config.i18n.report.read.actual_forecasting : config.i18n.report.read.actual}
                                            </HeaderTableCell>
                                            <SeparatorTableCell />
                                            { hasReference
                                                ? (
                                                    <>
                                                        <HeaderTableCell
                                                            colSpan={hideInitialMonths ? 3 : dateColumnsActual.length + otherColumns.length}
                                                        >
                                                            {config.i18n.report.read.ar_diff}
                                                        </HeaderTableCell>
                                                        <SeparatorTableCell />
                                                        <HeaderTableCell
                                                            colSpan={hideInitialMonths ? 3 : dateColumnsActual.length + otherColumns.length}
                                                        >
                                                            {config.i18n.report.read.ar_div}
                                                        </HeaderTableCell>
                                                        <SeparatorTableCell />
                                                        <HeaderTableCell
                                                            colSpan={hideInitialMonths ? 3 : dateColumnsActual.length + otherColumns.length}
                                                        >
                                                            {hasForecasting() ? config.i18n.report.read.reference_forecasting : config.i18n.report.read.reference}
                                                        </HeaderTableCell>
                                                    </>
                                                    )
                                                : null}
                                        </TableRow>
                                        <TableRow style={{ height: "32px" }}>
                                             {dateColumnsActual.map((key, colIndex) => (
                                                 showColumn(colIndex) ? (
                                                     <SubHeaderTableCell key={key}>
                                                         {formatYearMonthShort(key, config.locale)}
                                                     </SubHeaderTableCell>
                                                 ) : null
                                             ))}
                                            {otherColumns.map(key => (
                                                <SubHeaderTableCell key={key}>
                                                    {key}
                                                </SubHeaderTableCell>
                                            ))}
                                            <SeparatorTableCell />
                                            { hasReference
                                                ? (
                                                    <>
                                                        {dateColumnsActual.map((key, colIndex) => (
                                                            showColumn(colIndex) ? (
                                                                <SubHeaderTableCell key={key}>
                                                                    {formatYearMonthShort(key, config.locale)}
                                                                </SubHeaderTableCell>
                                                            ) : null
                                                        ))}
                                                        {otherColumns.map(key => (
                                                            <SubHeaderTableCell key={key}>
                                                                {key}
                                                            </SubHeaderTableCell>
                                                        ))}
                                                        <SeparatorTableCell />
                                                        {dateColumnsActual.map((key, colIndex) => (
                                                            showColumn(colIndex) ? (
                                                                <SubHeaderTableCell key={key}>
                                                                    {formatYearMonthShort(key, config.locale)}
                                                                </SubHeaderTableCell>
                                                            ) : null
                                                        ))}
                                                        {otherColumns.map(key => (
                                                            <SubHeaderTableCell key={key}>
                                                                {key}
                                                            </SubHeaderTableCell>
                                                        ))}
                                                        <SeparatorTableCell />
                                                        {dateColumnsReference.map((key, colIndex) => (
                                                            showColumn(colIndex) ? (
                                                                <SubHeaderTableCell key={key}>
                                                                    {formatYearMonthShort(key, config.locale)}
                                                                </SubHeaderTableCell>
                                                            ) : null
                                                        ))}
                                                        {otherColumns.map(key => (
                                                            <SubHeaderTableCell key={key}>
                                                                {key}
                                                            </SubHeaderTableCell>
                                                        ))}
                                                    </>
                                                    )
                                                : null}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportResults.results.filter((row, index) => (visibility[index] || row.indentation === 0) && !hideRow(row, index)).map((row) => {
                                            const index = reportResults.results.findIndex(r => r === row);

                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    style={{
                                                        fontStyle: (row.text_style === "italic" || row.text_style === "normal") ? row.text_style : null,
                                                        fontWeight: (row.text_style === "bold" || row.text_style === "header") ? "bold" : null,
                                                        backgroundColor: (row.is_header || row.text_style === "header") ? theme.palette.reportHeaderRowGrey.main : "white",
                                                    }}
                                                >
                                                    <IndexTableCell
                                                        sx={row.analysis_link ? { "&:hover": { backgroundColor: theme.palette.grey["200"], cursor: "pointer", textDecoration: "underline" } } : null}
                                                        style={{
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Stack
                                                            data-cy={`report-link-${row.id}`}
                                                            component="a"
                                                            href={generateReportLink(row, startDate, endDate, filtersInParameters, filtersOutParameters, scenario, cutoffDate)}
                                                            target="_blank"
                                                            direction="row"
                                                            alignItems="center"
                                                            gap={1}
                                                            paddingTop="6px"
                                                            paddingBottom="6px"
                                                            paddingRight="16px"
                                                            paddingLeft={row.indentation * 3}
                                                            style={{
                                                                textDecoration: "none",
                                                                color: "inherit",
                                                                whiteSpace: "nowrap",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            <Box marginLeft={0.5} marginTop={-1} marginRight={-0.5} marginBottom={-1} width="1.6rem">
                                                                {isParentRow(index, reportResults.results[index]?.indentation)
                                                                && (!hideEmptyRows || !areChildrenRowsEmpty(row, index))
                                                                && (
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            event.stopPropagation();
                                                                            toggleOpen(index);
                                                                        }}
                                                                    >
                                                                        {!open[index]
                                                                            ? <KeyboardArrowRightIcon sx={{ fontSize: "1rem" }} />
                                                                            : <KeyboardArrowDownIcon sx={{ fontSize: "1rem" }} />}
                                                                    </IconButton>
                                                                )}
                                                            </Box>
                                                            {row.name}
                                                        </Stack>
                                                    </IndexTableCell>
                                                    {dateColumnsActual.map((key, colIndex) => {
                                                        if (!showColumn(colIndex)) {
                                                            return null;
                                                        }

                                                        if (row.is_header) {
                                                            return <EmptyTableCell key={key} />;
                                                        }

                                                        return (
                                                            <StyledTableCell
                                                                title={getFormattedValue(row.actual.dates[key], "y", row, config, row.label_type_actual, false, true, true)}
                                                                key={key}
                                                                color={hasForecasting() && key >= cutoffDate ? theme.palette.reportForecastCellGrey.main : null}
                                                            >
                                                                {getFormattedCellValue(row, index, row.actual.dates[key], row.label_type_actual, "actual")}
                                                            </StyledTableCell>
                                                        );
                                                    })}
                                                    {
                                                        row.is_header ? (
                                                            <>
                                                                <EmptyTableCell />
                                                                <EmptyTableCell />
                                                            </>
                                                        ) : (
                                                            <>
                                                                 <StyledTableCell
                                                                     title={getFormattedValue(row.actual.ytd, "y", row, config, row.label_type_actual, false, true, true)}
                                                                 >
                                                                    {getFormattedCellValue(row, index, row.actual.ytd, row.label_type_actual, "actual")}
                                                                 </StyledTableCell>
                                                                 <StyledTableCell
                                                                     title={getFormattedValue(row.actual.fy, "y", row, config, row.label_type_actual, false, true, true)}
                                                                 >
                                                                    {getFormattedCellValue(row, index, row.actual.fy, row.label_type_actual, "actual")}
                                                                 </StyledTableCell>
                                                            </>
                                                        )
                                                    }
                                                     { hasReference
                                                         ? (
                                                            <>
                                                                <SeparatorTableCell />
                                                                {dateColumnsActual.map((key, colIndex) => {
                                                                    if (!showColumn(colIndex)) {
                                                                        return null;
                                                                    }

                                                                    if (row.is_header) {
                                                                        return <EmptyTableCell key={key} />;
                                                                    }

                                                                    return (
                                                                        <StyledTableCell
                                                                            title={getFormattedValue(row.ar_diff?.dates[key], "y", row, config, row.label_type_ar_diff,
                                                                                false, true, true)}
                                                                            key={key}
                                                                            color={hasForecasting() && key >= cutoffDate ? theme.palette.reportForecastCellGrey.main : null}
                                                                        >
                                                                            {getFormattedCellValue(row, index, row.ar_diff?.dates[key], row.label_type_ar_diff, "ar_diff")}
                                                                        </StyledTableCell>
                                                                    );
                                                                })}
                                                                {
                                                                    row.is_header ? (
                                                                        <>
                                                                            <EmptyTableCell />
                                                                            <EmptyTableCell />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <StyledTableCell
                                                                                title={getFormattedValue(row.ar_diff?.ytd, "y", row, config, row.label_type_ar_diff, false, true, true)}
                                                                            >
                                                                                {getFormattedCellValue(row, index, row.ar_diff?.ytd, row.label_type_ar_diff, "ar_diff")}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell
                                                                                title={getFormattedValue(row.ar_diff?.fy, "y", row, config, row.label_type_ar_diff, false, true, true)}
                                                                            >
                                                                                {getFormattedCellValue(row, index, row.ar_diff?.fy, row.label_type_ar_diff, "ar_diff")}
                                                                            </StyledTableCell>
                                                                        </>
                                                                    )
                                                                }
                                                                <SeparatorTableCell />
                                                                {dateColumnsActual.map((key, colIndex) => {
                                                                    if (!showColumn(colIndex)) {
                                                                        return null;
                                                                    }

                                                                    if (row.is_header) {
                                                                        return <EmptyTableCell key={key} />;
                                                                    }

                                                                    return (
                                                                        <StyledTableCell
                                                                            title={getFormattedValue(row.ar_div?.dates[key], "y", row, config, row.label_type_ar_div,
                                                                                false, true, true)}
                                                                            key={key}
                                                                            color={hasForecasting() && key >= cutoffDate ? theme.palette.reportForecastCellGrey.main : null}
                                                                        >
                                                                            {getFormattedCellValue(row, index, row.ar_div?.dates[key], row.label_type_ar_div, "ar_div")}
                                                                        </StyledTableCell>
                                                                    );
                                                                })}
                                                                {
                                                                    row.is_header ? (
                                                                        <>
                                                                            <EmptyTableCell />
                                                                            <EmptyTableCell />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <StyledTableCell
                                                                                title={getFormattedValue(row.ar_div?.ytd, "y", row, config, row.label_type_ar_div, false, true, true)}
                                                                            >
                                                                                {getFormattedCellValue(row, index, row.ar_div?.ytd, row.label_type_ar_div, "ar_div")}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell
                                                                                title={getFormattedValue(row.ar_div?.fy, "y", row, config, row.label_type_ar_div, false, true, true)}
                                                                            >
                                                                                {getFormattedCellValue(row, index, row.ar_div?.fy, row.label_type_ar_div, "ar_div")}
                                                                            </StyledTableCell>
                                                                        </>
                                                                    )
                                                                }
                                                                <SeparatorTableCell />
                                                                {dateColumnsReference.map((key, colIndex) => {
                                                                    if (!showColumn(colIndex)) {
                                                                        return null;
                                                                    }

                                                                    if (row.is_header) {
                                                                        return <EmptyTableCell key={key} />;
                                                                    }

                                                                    return (
                                                                        <StyledTableCell
                                                                            title={getFormattedValue(row.reference.dates[key], "y", row, config, row.label_type_reference,
                                                                                false, true, true)}
                                                                            key={key}
                                                                            color={hasForecasting() && key >= cutoffDate ? theme.palette.reportForecastCellGrey.main : null}
                                                                        >
                                                                            {getFormattedCellValue(row, index, row.reference.dates[key], row.label_type_reference, "reference")}
                                                                        </StyledTableCell>
                                                                    );
                                                                })}
                                                                {
                                                                    row.is_header ? (
                                                                        <>
                                                                            <EmptyTableCell />
                                                                            <EmptyTableCell />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <StyledTableCell
                                                                                title={getFormattedValue(row.reference?.ytd, "y", row, config, row.label_type_reference,
                                                                                    false, true, true)}
                                                                            >
                                                                                {getFormattedCellValue(row, index, row.reference?.ytd, row.label_type_reference, "reference")}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell
                                                                                title={getFormattedValue(row.reference?.fy, "y", row, config, row.label_type_reference,
                                                                                    false, true, true)}
                                                                            >
                                                                                {getFormattedCellValue(row, index, row.reference?.fy, row.label_type_reference, "reference")}
                                                                            </StyledTableCell>
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                             )
                                                         : null}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        ) : null}
            </AdvisorContainer>
            { renderDrawer
                ? (
                    <Drawer
                        variant="persistent"
                        anchor="right"
                        open={isCustomizationDrawerOpen}
                        sx={{
                            "width": isCustomizationDrawerOpen ? drawerWidth : 0,
                            "flexShrink": 0,
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                boxSizing: "border-box",
                            },
                        }}
                    >
                        <Toolbar />
                        <IconButton
                            onClick={() => setIsCustomizationDrawerOpen(!isCustomizationDrawerOpen)}
                            sx={{ mr: 1, mb: -2, alignSelf: "end" }}
                        >
                            <KeyboardDoubleArrowRightIcon />
                        </IconButton>
                        <ReportCustomizationBar
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            globalMin={historicalMin}
                            globalMax={historicalMax}
                            maxDate={maxDate}
                            setMaxDate={setMaxDate}
                            scenario={scenario}
                            setScenario={setScenario}
                            scenarioOptions={scenarioOptions}
                            multiplier={multiplier}
                            setMultiplier={setMultiplier}
                            multiplierOptions={multiplierOptions}
                            hideRows={hideEmptyRows}
                            setHideRows={setHideEmptyRows}
                            hideInitialMonths={hideInitialMonths}
                            setHideInitialMonths={setHideInitialMonths}
                            documentDimensions={documentDimensions}
                            UIFiltersInDimensions={filtersInDimensions}
                            UIFiltersInParameters={filtersInParameters}
                            UIFiltersOutDimensions={filtersOutDimensions}
                            UIFiltersOutParameters={filtersOutParameters}
                            reportDimensionsMetadata={reportDimensionsMetadata}
                            cutoffDate={cutoffDate}
                            setCutoffDate={setCutoffDate}
                            reportParameters={reportParameters}
                            onFiltersInUpdate={(dimensions, dimensionFilters, metadata = null) => {
                                // only set dimensionFilters if it isn't equal to previous.
                                if (!isEqual(filtersInParameters, dimensionFilters)) {
                                    setFiltersInParameters(dimensionFilters);
                                }

                                setFiltersInDimensions(dimensions);
                                setReportDimensionsMetadata({
                                    ...reportDimensionsMetadata,
                                    ...metadata,
                                });
                            }}
                            onFiltersOutUpdate={(dimensions, dimensionFilters, metadata = null) => {
                                // only set dimensionFilters if it isn't equal to previous.
                                if (!isEqual(filtersOutParameters, dimensionFilters)) {
                                    setFiltersOutParameters(dimensionFilters);
                                }

                                setFiltersOutDimensions(dimensions);
                                setReportDimensionsMetadata({
                                    ...reportDimensionsMetadata,
                                    ...metadata,
                                });
                            }}
                            disabled={!reportResults || computingReport}
                        />
                    </Drawer>
                    ) : null}
            { !isCustomizationDrawerOpen
                ? (
                    <Box position="absolute" top={0} right={0}>
                        <Toolbar />
                        <IconButton
                            onClick={() => setIsCustomizationDrawerOpen(!isCustomizationDrawerOpen)}
                            sx={{ alignSelf: "start" }}
                        >
                            <KeyboardDoubleArrowLeftIcon />
                        </IconButton>
                    </Box>
                    )
                : null}
        </>
    );
};

export default ReadReport;
