import PropTypes from "prop-types";
import React, { useContext } from "react";

import { Box, Grid, IconButton, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { formatCompactAmount, formatInteger, getCurrencySymbol } from "../../../../util/formatter";
import ReadOnlyTextField from "../../../../components/field/ReadOnlyTextField.js";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import CommentBox from "../../../../components/CommentBox";
import ValueField from "../../../../components/field/ValueField";
import { hasValue } from "../../../../util/util";
import { AppContext } from "../../../../AppRouter";
import { Stack } from "@mui/system";

const ValueProvenTable = ({
    initiative,
    shouldValidate,
    onInitiativeValuesUpdate,
    onCommentUpdate,
}) => {
    const { config } = useContext(AppContext);

    if (!initiative) {
        return null;
    }

    const currencySymbol = getCurrencySymbol(config.locale);

    return (
        <>
            <Typography variant="h6" align="center" sx={{ mb: 3 }}>
                {config.i18n.procurement.identify.value_proven.title}
            </Typography>

            <Grid container spacing={2}>
                <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{ mb: 1 }}>
                    <Grid item xs={2} />
                    <Grid item xs={3}>
                        <Typography align="center">{config.i18n.procurement.identify.value_proven.estimated}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography align="center">{config.i18n.procurement.identify.value_proven.adjusted}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography align="center">{config.i18n.procurement.identify.value_proven.comment}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{ mb: 1 }}>
                    <Grid item xs={2}>
                        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                            <Typography gutterBottom>{config.i18n.procurement.identify.value_proven.addressable_spend}</Typography>
                            <Tooltip followCursor placement="bottom-start" title={config.i18n.procurement.hints[initiative.initiative_id].addressable_spend_hint}>
                                <InfoOutlinedIcon sx={{ fontSize: 20 }} />
                            </Tooltip>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <ReadOnlyTextField
                            disabled={!!initiative?.adjusted?.addressable_spend}
                            value={formatCompactAmount(initiative.estimated.addressable_spend, config.locale)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ValueField
                            dataCyProp="adjusted_addressable_spend"
                            defaultValue={initiative.estimated.addressable_spend}
                            value={hasValue(initiative?.adjusted?.addressable_spend) ? initiative.adjusted.addressable_spend : ""}
                            formatter={value => formatCompactAmount(value, config.locale)}
                            validator={value => value === "" || value >= 0}
                            adornment={currencySymbol}
                            type="AMOUNT"
                            onChange={(value) => {
                                onInitiativeValuesUpdate({
                                    ...initiative,
                                    adjusted: {
                                        ...initiative.adjusted,
                                        addressable_spend: hasValue(value) ? value : null,
                                    },
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CommentBox
                            dataCy="notes_addressable_spend"
                            required={!!initiative?.adjusted?.addressable_spend}
                            error={shouldValidate && initiative?.adjusted?.addressable_spend ? !initiative?.comment?.addressable_spend : null}
                            value={initiative?.comment?.addressable_spend || ""}
                            onUpdate={comment => onCommentUpdate({
                                ...initiative,
                                comment: {
                                    ...initiative.comment,
                                    addressable_spend: comment,
                                },
                            })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{ mb: 1 }}>
                    <Grid item xs={2}>
                        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                            <Typography gutterBottom>{config.i18n.procurement.identify.value_proven.run_rate}</Typography>
                            <Tooltip followCursor placement="bottom-start" title={config.i18n.procurement.hints[initiative.initiative_id].run_rate_hint}>
                                <InfoOutlinedIcon sx={{ fontSize: 20 }} />
                            </Tooltip>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <ReadOnlyTextField
                            disabled={!!initiative?.adjusted?.run_rate}
                            value={formatCompactAmount(initiative.estimated.run_rate, config.locale)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ValueField
                            dataCyProp="adjusted_run_rate"
                            defaultValue={initiative.estimated.run_rate}
                            value={hasValue(initiative?.adjusted?.run_rate) ? initiative.adjusted.run_rate : ""}
                            formatter={value => formatCompactAmount(value, config.locale)}
                            validator={value => value === "" || value >= 0}
                            adornment={currencySymbol}
                            type="AMOUNT"
                            onChange={(value) => {
                                onInitiativeValuesUpdate({
                                    ...initiative,
                                    adjusted: {
                                        ...initiative.adjusted,
                                        run_rate: hasValue(value) ? value : null,
                                    },
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CommentBox
                            dataCy="notes_run_rate"
                            required={!!initiative?.adjusted?.run_rate}
                            error={shouldValidate && initiative?.adjusted?.run_rate ? !initiative?.comment?.run_rate : null}
                            value={initiative?.comment?.run_rate || ""}
                            onUpdate={comment => onCommentUpdate({
                                ...initiative,
                                comment: {
                                    ...initiative.comment,
                                    run_rate: comment,
                                },
                            })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{ mb: 1 }}>
                    <Grid item xs={2}>
                        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                            <Typography gutterBottom>{config.i18n.procurement.identify.value_proven.cost_to_achieve}</Typography>
                            <Tooltip followCursor placement="bottom-start" title={config.i18n.procurement.hints[initiative.initiative_id].cost_to_achieve_hint}>
                                <InfoOutlinedIcon sx={{ fontSize: 20 }} />
                            </Tooltip>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <ReadOnlyTextField
                            disabled={!!initiative?.adjusted?.cost_to_achieve}
                            value={formatCompactAmount(initiative.estimated.cost_to_achieve, config.locale)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ValueField
                            dataCyProp="adjusted_cost_to_achieve"
                            defaultValue={initiative.estimated.cost_to_achieve}
                            value={hasValue(initiative?.adjusted?.cost_to_achieve) ? initiative.adjusted.cost_to_achieve : ""}
                            validator={value => value === "" || value >= 0}
                            formatter={value => formatCompactAmount(value, config.locale)}
                            adornment={currencySymbol}
                            type="AMOUNT"
                            onChange={(value) => {
                                onInitiativeValuesUpdate({
                                    ...initiative,
                                    adjusted: {
                                        ...initiative.adjusted,
                                        cost_to_achieve: hasValue(value) ? value : null,
                                    },
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CommentBox
                            dataCy="notes_cost_to_achieve"
                            required={!!initiative?.adjusted?.cost_to_achieve}
                            error={shouldValidate && initiative?.adjusted?.cost_to_achieve ? !initiative?.comment?.cost_to_achieve : null}
                            value={initiative?.comment?.cost_to_achieve || ""}
                            onUpdate={comment => onCommentUpdate({
                                ...initiative,
                                comment: {
                                    ...initiative.comment,
                                    cost_to_achieve: comment,
                                },
                            })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{ mb: 1 }}>
                    <Grid item xs={2}>
                        <Typography align="right">{config.i18n.procurement.slope.label}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <ReadOnlyTextField
                            disabled={!!initiative?.adjusted?.slope}
                            value={config.i18n.procurement.slope.values[initiative.estimated.slope]}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            fullWidth
                            data-cy="adjusted_slope"
                            size="small"
                            value={hasValue(initiative?.adjusted?.slope) ? initiative.adjusted.slope : ""}
                            onChange={(e) => {
                                onInitiativeValuesUpdate({
                                    ...initiative,
                                    adjusted: {
                                        ...initiative.adjusted,
                                        slope: e.target.value ? e.target.value : null,
                                    },
                                });
                            }}
                            startAdornment={
                                // HACK
                                <Box sx={{ width: 170 }} />
                            }
                            endAdornment={
                                initiative?.adjusted?.slope
                                    ? (
                                        <IconButton
                                            onClick={() => {
                                                onInitiativeValuesUpdate({
                                                    ...initiative,
                                                    adjusted: {
                                                        ...initiative.adjusted,
                                                        slope: null,
                                                    },
                                                });
                                            }}
                                            sx={{ mr: 1.5 }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                        )
                                    : null
                            }
                            sx={{
                                ".MuiSelect-select": {
                                    textAlign: "center",
                                },
                            }}
                        >
                            <MenuItem key="" value="" sx={{ justifyContent: "center" }}>
                                <em>{config.i18n.procurement.identify.value_proven.none}</em>
                            </MenuItem>
                            {config.procurement.slope.map(key => (
                                <MenuItem key={key} value={key} sx={{ justifyContent: "center" }} data-cy={key}>
                                    {config.i18n.procurement.slope.values[key]}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={4}>
                        <CommentBox
                            dataCy="notes_slope"
                            required={!!initiative?.adjusted?.slope}
                            error={shouldValidate && initiative?.adjusted?.slope ? !initiative?.comment?.slope : null}
                            value={initiative?.comment?.slope || ""}
                            onUpdate={comment => onCommentUpdate({
                                ...initiative,
                                comment: {
                                    ...initiative.comment,
                                    slope: comment,
                                },
                            })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{ mb: 1 }}>
                    <Grid item xs={2}>
                        <Typography align="right">{config.i18n.procurement.identify.value_proven.lead_time}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <ReadOnlyTextField
                            disabled={!!initiative?.adjusted?.lead_time}
                            value={initiative.estimated.lead_time + " "
                            + config.i18n.procurement.identify.value_proven.lead_time_unit}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ValueField
                            dataCyProp="adjusted_lead_time"
                            defaultValue={initiative.estimated.lead_time}
                            value={hasValue(initiative?.adjusted?.lead_time) ? initiative.adjusted.lead_time : ""}
                            validator={value => value === "" || value >= 0}
                            formatter={value =>

                                `${formatInteger(value, config.locale)} ${config.i18n.procurement.identify.value_proven.lead_time_unit}`}
                            adornment={config.i18n.procurement.identify.value_proven.lead_time_unit}
                            type="number"
                            onChange={(value) => {
                                onInitiativeValuesUpdate({
                                    ...initiative,
                                    adjusted: {
                                        ...initiative.adjusted,
                                        lead_time: hasValue(value) ? value : null,
                                    },
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CommentBox
                            dataCy="notes_lead_time"
                            required={!!initiative?.adjusted?.lead_time}
                            error={shouldValidate && initiative?.adjusted?.lead_time ? !initiative?.comment?.lead_time : null}
                            value={initiative?.comment?.lead_time || ""}
                            onUpdate={comment => onCommentUpdate({
                                ...initiative,
                                comment: {
                                    ...initiative.comment,
                                    lead_time: comment,
                                },
                            })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{ mb: 1 }}>
                    <Grid item xs={2}>
                        <Typography align="right">{config.i18n.procurement.identify.value_proven.span}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <ReadOnlyTextField
                            disabled={!!initiative?.adjusted?.span}
                            value={initiative.estimated.span + " "
                            + config.i18n.procurement.identify.value_proven.span_unit}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ValueField
                            dataCyProp="adjusted_span"
                            defaultValue={initiative.estimated.span}
                            value={hasValue(initiative?.adjusted?.span) ? initiative.adjusted.span : ""}
                            validator={value => value === "" || value >= 0}
                            formatter={value =>

                                `${formatInteger(value, config.locale)} ${config.i18n.procurement.identify.value_proven.span_unit}`}
                            adornment={config.i18n.procurement.identify.value_proven.span_unit}
                            type="number"
                            onChange={(value) => {
                                onInitiativeValuesUpdate({
                                    ...initiative,
                                    adjusted: {
                                        ...initiative.adjusted,
                                        span: hasValue(value) ? value : null,
                                    },
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CommentBox
                            dataCy="notes_span"
                            required={!!initiative?.adjusted?.span}
                            error={shouldValidate && initiative?.adjusted?.span ? !initiative?.comment?.span : null}
                            value={initiative?.comment?.span || ""}
                            onUpdate={comment => onCommentUpdate({
                                ...initiative,
                                comment: {
                                    ...initiative.comment,
                                    span: comment,
                                },
                            })}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

ValueProvenTable.propTypes = {
    initiative: PropTypes.object,
    shouldValidate: PropTypes.bool,
    onInitiativeValuesUpdate: PropTypes.func,
    onCommentUpdate: PropTypes.func,
};

ValueProvenTable.defaultProps = {
    shouldValidate: false,
    onInitiativeValuesUpdate: () => {
    },
    onCommentUpdate: () => {
    },
};

export default ValueProvenTable;
