import { getCategoricalAxisOptions, getFormattedValue, getFormatter, getNumericalAxisOptions, getXData } from "./common";
import { sum } from "lodash";
import theme from "../../theme/theme";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    const yData = data.result.map(entry => entry.y[0]);

    let cumul = 0;
    let prev = 0;
    const transparentData = yData.map((value) => {
        cumul += prev;
        prev = value;
        return cumul;
    });
    transparentData.push(0); // Total column has transparentData value at 0

    const visibleData = [...yData, sum(yData)];

    // random name to stack series
    return {
        xAxis: getCategoricalAxisOptions(data.labels.x, [...getXData(data, config), "Total"], config, baseFontSize, 1),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            confine: true,
            textStyle: { fontSize: 0.4 * baseFontSize },
            trigger: "axis",
            axisPointer: { type: "shadow" },
            formatter: (params) => {
                const formatter = getFormatter(data, "y", config, false);

                // Params at index 1 is the visible series
                return `${params[1].name} <br/> ${params[1].marker} ${formatter(params[1].value)}`;
            },
        },
        series: [{
            name: "transparent",
            type: "bar",
            stack: "waterfall",
            stackStrategy: "all",
            itemStyle: {
                borderColor: "transparent",
                color: "transparent",
            },
            data: transparentData,
        },
        {
            name: "values",
            type: "bar",
            stack: "waterfall",
            stackStrategy: "all",
            itemStyle: {
                color: (value) => {
                    if (value.dataIndex === visibleData.length - 1) {
                        return theme.palette.echartsBlue.main;
                    } else if (value.data >= 0) {
                        return theme.palette.echartsLightGreen.main;
                    } else {
                        return theme.palette.echartsRed.main;
                    }
                },
            },
            data: visibleData,
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(Math.abs(params.value), "y", data, config),
            },
        }],
    };
};
