import PropTypes from "prop-types";
import React, { useContext } from "react";
import { isEmpty, union, without } from "lodash";

import { parseCustomizationParameters } from "../util/parameters";
import DimensionBox from "./DimensionBox";
import { hasValue } from "../util/util";
import CustomParameterSelector from "./CustomParameterSelector";
import DimensionSelector from "./selector/DimensionSelector";
import DateSelector from "./selector/DateSelector";
import CommonSelector from "./selector/CommonSelector";
import CurrencySelector from "./selector/CurrencySelector";
import { Stack } from "@mui/system";
import { AppContext } from "../AppRouter";

const CustomizationBar = ({
    customizationParameters,
    analysisParameters,
    UIFiltersInDimensions,
    UIFiltersInParameters,
    onAnalysisFiltersInUpdate,
    UIFiltersOutDimensions,
    UIFiltersOutParameters,
    onAnalysisFiltersOutUpdate,
    analysisCustomParameters,
    analysisChart,
    analysisHideInsights,
    statisticalLines,
    analysisMetadata,
    onParameterUpdate,
    onChartUpdate,
    onHideInsightsUpdate,
    onCustomParameterUpdate,
    onStatisticalLinesUpdate,
    disabled,
}) => {
    const { config } = useContext(AppContext);

    function getCommonSelector(chartParam, limitParam, hideTailParam, ignoreNullParam, statLinesParam, hideInsightsParam) {
        if (!chartParam && !limitParam && !hideTailParam && !ignoreNullParam && !statLinesParam) {
            return null;
        }

        let chart = null;
        if (chartParam) {
            chart = {
                options: chartParam.options,
                value: analysisChart,
                onUpdate: onChartUpdate,
            };
        }

        let limit = null;
        if (limitParam) {
            limit = {
                options: limitParam.options,
                value: analysisParameters["LIMIT"],
                onUpdate: value => onParameterUpdate({ LIMIT: value }),
            };
        }

        let hideTail = null;
        if (hideTailParam) {
            hideTail = {
                value: analysisParameters["HIDE_TAIL"],
                onUpdate: value => onParameterUpdate({ HIDE_TAIL: value }),
            };
        }

        let ignoreNull = null;
        if (ignoreNullParam) {
            ignoreNull = {
                value: analysisParameters["IGNORE_NULL"],
                onUpdate: value => onParameterUpdate({ IGNORE_NULL: value }),
            };
        }

        let statLines = null;
        if (statLinesParam) {
            statLines = {
                options: statLinesParam.options,
                value: statisticalLines.statisticalLines,
                onUpdate: onStatisticalLinesUpdate,
                disabled: statisticalLines.disabled,
            };
        }

        let hideInsights = null;
        if (hideInsightsParam) {
            hideInsights = {
                value: analysisHideInsights,
                onUpdate: onHideInsightsUpdate,
            };
        }

        return (
            <CommonSelector
                key="common-selector"
                disabled={disabled}
                chart={chart}
                limit={limit}
                tail={hideTail}
                ignoreNull={ignoreNull}
                statLines={statLines}
                hideInsights={hideInsights}
            />
        );
    }

    function getDateSelector(dateParam, dateAggParam, annualizeParam, cumulativeParam, scenarioParam) {
        if (!dateParam) {
            return null;
        }

        let dateAgg = null;
        if (dateAggParam) {
            const dateAggValue = analysisParameters["DATE_AGG"];
            dateAgg = {
                options: dateAggParam.options,
                value: hasValue(dateAggValue) ? dateAggValue : dateAggParam.default,
                onUpdate: (value) => {
                    if (["DATE_YEAR_MONTH", "DATE_YEAR_QUARTER"].includes(value)) {
                        onParameterUpdate({ DATE_AGG: value, ANNUALIZE: false });
                    } else if (["FISCAL_YEAR"].includes(value)) {
                        onParameterUpdate({ DATE_AGG: value, SCENARIO: {} }); // Remove selected scenario when fiscal is selected
                    } else {
                        onParameterUpdate({ DATE_AGG: value });
                    }
                },
            };
        }

        let annualize = null;
        if (annualizeParam) {
            // It is always possible to annualize, except when time aggregation parameter exists, and it aggregates to month or quarter
            const annualizeValue = analysisParameters["ANNUALIZE"];
            annualize = {
                disabled: ["DATE_YEAR_MONTH", "DATE_YEAR_QUARTER"].includes(analysisParameters["DATE_AGG"]),
                value: hasValue(annualizeValue) ? annualizeValue : annualizeParam.default,
                onUpdate: value => onParameterUpdate({ ANNUALIZE: value }),
            };
        }

        let cumulative = null;
        if (cumulativeParam) {
            cumulative = {
                value: analysisParameters["CUMULATIVE"],
                onUpdate: value => onParameterUpdate({ CUMULATIVE: value }),
            };
        }

        let scenario = null;
        if (scenarioParam) {
            scenario = {
                options: scenarioParam.options,
                selected: analysisParameters["SCENARIO"],
                cutoff_date: hasValue(analysisParameters["CUTOFF_DATE"]) ? analysisParameters["CUTOFF_DATE"] : scenarioParam.cutoff_date,
            };
        }

        const date = analysisParameters["DATE"];
        return (
            <DateSelector
                key="date-selector"
                disabled={disabled}
                date={date}
                dateAgg={dateAgg}
                annualize={annualize}
                cumulative={cumulative}
                scenario={scenario}
                onUpdate={(minDate, maxDate, scenario, cutoffDate) =>
                    onParameterUpdate({ DATE: [minDate, maxDate], SCENARIO: scenario, CUTOFF_DATE: cutoffDate })}
            />
        );
    }

    function getCurrencySelector(forexDateParam, dateParam) {
        if (!dateParam || !forexDateParam) {
            return null;
        }

        const date = analysisParameters["DATE"];

        let forexDate = null;
        if (forexDateParam) {
            forexDate = analysisParameters["FOREX_DATE"];
        }

        return (
            <CurrencySelector
                key="currency-selector"
                disabled={disabled}
                forexDate={forexDate}
                datePickers={date}
                onUpdate={value => onParameterUpdate({ FOREX_DATE: value !== null ? value : null })}
            />
        );
    }

    function getDimensionSelector(analysisDimensions, analysisDimParameters, filtersInParameters, filtersOutParameters, onDimensionUpdate, title, key,
        isFiltersIn, dimensionParam) {
        return (
            <DimensionSelector
                key={key}
                dataCyProp={`${key}-dim-selector`}
                title={title}
                disabled={disabled || !dimensionParam}
                documentDimensions={dimensionParam}
                dimensions={analysisDimensions}
                dimensionFilters={analysisDimParameters}
                filtersInParameters={filtersInParameters}
                filtersOutParameters={filtersOutParameters}
                metadata={analysisMetadata}
                parameters={analysisParameters}
                isFiltersIn={isFiltersIn}
                onUpdate={(dimension, dimensionFilters = null, metadata = null) => {
                    const newDimensions = union(analysisDimensions, [dimension]);

                    const newAnalysisDimParameters = { ...analysisDimParameters };

                    // remove entry on dimension parameters, if the value is empty.
                    if (dimensionFilters !== null) {
                        Object.entries(dimensionFilters).forEach(([key, value]) => {
                            if (isEmpty(value)) {
                                delete newAnalysisDimParameters[key];
                            } else {
                                newAnalysisDimParameters[key] = value;
                            }
                        });
                    }

                    // HACK: when a filter is set, existing filters out of that dimension should be removed
                    if (isFiltersIn) {
                        if (dimension in config.hierarchy && config.hierarchy[dimension].some(key => key in newAnalysisDimParameters)) {
                            config.hierarchy[dimension].forEach((key) => {
                                delete filtersOutParameters[key];
                            });
                        } else if (dimension in newAnalysisDimParameters) {
                            delete filtersOutParameters[dimension];
                        }
                        onAnalysisFiltersOutUpdate(UIFiltersOutDimensions, filtersOutParameters, metadata);
                    }

                    onDimensionUpdate(newDimensions, newAnalysisDimParameters, metadata);
                }}
                onDelete={(dimension, dimensionKeys) => {
                    const newDimensions = without(analysisDimensions, dimension);
                    [dimension, ...dimensionKeys].forEach(key => delete analysisDimParameters[key]);

                    onDimensionUpdate(newDimensions, analysisDimParameters);
                }}
            />
        );
    }

    const elementsByType = parseCustomizationParameters(customizationParameters);
    const final = [
        getCommonSelector(elementsByType.CHART, elementsByType.LIMIT, elementsByType.HIDE_TAIL, elementsByType.IGNORE_NULL, elementsByType.STAT_LINES,
            elementsByType.HIDE_INSIGHTS),
        getDateSelector(elementsByType.DATE, elementsByType.DATE_AGG, elementsByType.ANNUALIZE, elementsByType.CUMULATIVE, elementsByType.SCENARIO),
        getCurrencySelector(elementsByType.FOREX_DATE, elementsByType.DATE),
        getDimensionSelector(UIFiltersInDimensions, UIFiltersInParameters, UIFiltersInParameters, UIFiltersOutParameters, onAnalysisFiltersInUpdate,
            config.i18n.customization_bar.filters_in, "filters-in", true, elementsByType.DIMENSION),
        // FiltersOut not included when fetching the filter out dimensions so we can deselect them
        getDimensionSelector(UIFiltersOutDimensions, UIFiltersOutParameters, { ...UIFiltersInParameters, ...UIFiltersOutParameters }, {}, onAnalysisFiltersOutUpdate,
            config.i18n.customization_bar.filters_out, "filters-out", false, elementsByType.DIMENSION),
    ];

    if (elementsByType["CUSTOM"]) {
        final.push(
            <DimensionBox
                dataCyProp="custom_parameters_box"
                title={config.i18n.custom_parameters.title}
                key="user-box"
                collapsible
            >
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1.5}>
                    <CustomParameterSelector
                        definition={elementsByType["CUSTOM"]}
                        values={analysisCustomParameters}
                        disabled={disabled}
                        onCustomParameterUpdate={onCustomParameterUpdate}
                    />
                </Stack>
            </DimensionBox>,
        );
    }

    return (
        <Stack
            marginTop={2}
            px={1}
            spacing={1}
            datacy="customization_bar"
        >
            {final}
        </Stack>
    );
};

CustomizationBar.propTypes = {
    customizationParameters: PropTypes.array,
    analysisParameters: PropTypes.object,
    UIFiltersInDimensions: PropTypes.array,
    UIFiltersInParameters: PropTypes.object,
    onAnalysisFiltersInUpdate: PropTypes.func,
    UIFiltersOutDimensions: PropTypes.array,
    UIFiltersOutParameters: PropTypes.object,
    onAnalysisFiltersOutUpdate: PropTypes.func,
    analysisCustomParameters: PropTypes.object,
    analysisChart: PropTypes.string,
    analysisHideInsights: PropTypes.bool,
    statisticalLines: PropTypes.object,
    analysisMetadata: PropTypes.object,
    onParameterUpdate: PropTypes.func,
    onChartUpdate: PropTypes.func,
    onHideInsightsUpdate: PropTypes.func,
    onStatisticalLinesUpdate: PropTypes.func,
    onCustomParameterUpdate: PropTypes.func,
    disabled: PropTypes.bool,
};

export default CustomizationBar;
