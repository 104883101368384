import { defaultValueLabelSetting, getAxisTooltip, getCategoricalAxisOptions, getFormattedValue, getNumericalAxisOptions, getXData, getYSeriesData } from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        xAxis: getCategoricalAxisOptions(data.labels.x, getXData(data, config), config, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        series: getYSeriesData(data).map(series =>
            ({
                type: "line",
                data: series,
                areaStyle: {},
                label: defaultValueLabelSetting("top", baseFontSize, config,
                    value => getFormattedValue(value.data.value, "y", data, config)),
            })),
    };
};
