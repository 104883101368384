import Handlebars from "handlebars/dist/handlebars";
import { hasValue } from "./util";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { toPrecisionWithTrailingZeros } from "./math";
import {getQuarter} from "./time"; // eslint-disable-line

function localeToDayjs(language) {
    switch (language) {
        case "en-US":
            return "en";
        case "pt-PT":
            return "pt";
        default:
            return "en";
    }
}

export const formatInteger = (value, locale) => new Intl.NumberFormat(locale.language, {}).format(parseInt(value));

export const formatDecimal = (value, locale, numberOfDecimals = getNumberOfDecimalsToShow(value), useGrouping = "min2") =>
    new Intl.NumberFormat(locale.language, {
        minimumFractionDigits: numberOfDecimals,
        maximumFractionDigits: numberOfDecimals,
        useGrouping: useGrouping, // "min2" is the Intl.NumberFormat default value for useGrouping
    }).format(value);

export const formatAccountingDecimal = (value, locale, numberOfDecimals = getNumberOfDecimalsToShow(value)) =>
    new Intl.NumberFormat(locale.language, {
        style: "currency",
        currency: locale.currency,
        currencySign: "accounting",
        currencyDisplay: "code",
        minimumFractionDigits: numberOfDecimals,
        maximumFractionDigits: numberOfDecimals,
    })
        .format(value)
        .replace(new RegExp(locale.currency + "\\s", "g"), "");

export const formatRatioToPercentage = (value, locale, adornment = true, numberOfDecimals = 0) =>
    new Intl.NumberFormat(locale.language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: numberOfDecimals,
    }).format(value * 100) + (adornment ? "%" : "");

export const formatIrr = (value, locale, infinityI18n) => {
    // format to Infinity, if value above 1000%.
    if (value > 10) {
        return `${infinityI18n} %`;
    } else if (value < -10) {
        return `-${infinityI18n} %`;
    } else {
        return formatRatioToPercentage(value, locale);
    }
};

export const formatBreakEven = (value, locale, i18n) =>
    hasValue(value)
        ? `${formatInteger(value, locale)} ${i18n.procurement.identify.priority.break_even_unit}`
        : `${i18n.never}`;

export const formatCompact = (value, locale, numberOfDecimals = getNumberOfDecimalsToShow(value)) =>
    new Intl.NumberFormat(locale.language, {
        minimumFractionDigits: numberOfDecimals,
        maximumFractionDigits: numberOfDecimals,
        notation: "compact",
        compactDisplay: "short",
    }).format(value);

export const formatCompactInteger = (value, locale, numberOfDecimals = getNumberOfDecimalsToShow(value)) =>
    new Intl.NumberFormat(locale.language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: numberOfDecimals,
        notation: "compact",
        compactDisplay: "short",
    }).format(parseInt(value));

export const formatDate = (value, locale) => dayjs.utc(value).locale(localeToDayjs(locale.language)).tz(locale.timezone || dayjs.tz.guess()).format("LL");

export const formatYear = (value, locale) => dayjs.utc(value).locale(localeToDayjs(locale.language)).format("YYYY");

export const formatYearMonth = (value, locale) => dayjs.utc(value).locale(localeToDayjs(locale.language)).format("MMM YYYY");

export const formatYearMonthDay = (value, locale) => dayjs.utc(value).locale(localeToDayjs(locale.language)).format("ll");

export const formatYearMonthShort = (value, locale) => dayjs.utc(value).locale(localeToDayjs(locale.language)).format("MMM'YY");

export const formatQuarter = (value) => {
    const year = dayjs.utc(value).year().toString();
    return `${getQuarter(value)}Q ${year}`;
};

export const formatFiscalYear = (value, metadata) => {
    return metadata[value].short_name;
};

export const formatDateTime = (value, locale) => dayjs.utc(value).locale(localeToDayjs(locale.language)).tz(locale.timezone || dayjs.tz.guess()).format("LLL");

export const formatAmount = (value, locale, accounting = false) =>
    new Intl.NumberFormat(locale.language, {
        style: "currency",
        currency: locale.currency,
        currencySign: accounting ? "accounting" : "standard",
    }).format(value);

export const formatCompactAmount = (value, locale, numberOfDecimals = getNumberOfDecimalsToShow(value)) =>
    new Intl.NumberFormat(locale.language, {
        style: "currency",
        currency: locale.currency,
        minimumFractionDigits: numberOfDecimals,
        maximumFractionDigits: numberOfDecimals,
        notation: "compact",
        compactDisplay: "short",
    }).format(value);

export const getCurrencySymbol = (locale) => {
    const format = new Intl.NumberFormat(locale.language, {
        style: "currency",
        currency: locale.currency,
    }).formatToParts(0);
    return format.find(part => part.type === "currency")?.value;
};

export function formatDimension(value, i18n, metadata) {
    if (isEmpty(value)) {
        return i18n.chart.label["__null__"];
    }

    return metadata[value]?.short_name || metadata[value]?.name || i18n.chart.label[value] || i18n.AccountingTreatment[value] || value;
}

const getHandlebars = (locale, i18n, metadata) => {
    const handlebars = Handlebars.create();

    // register formatting helpers
    handlebars.registerHelper("integer", value => formatInteger(value, locale));
    handlebars.registerHelper("decimal", value => formatDecimal(value, locale));
    handlebars.registerHelper("compact", value => formatCompact(value, locale));
    handlebars.registerHelper("compactinteger", value => formatCompactInteger(value, locale));
    handlebars.registerHelper("percentage", (value) => {
        if (Math.abs(value) < 0.01) {
            return formatRatioToPercentage(value, locale, true, 2);
        }
        return formatRatioToPercentage(value, locale);
    });
    handlebars.registerHelper("reportpercentage", value => formatRatioToPercentage(value, locale, true, 2));
    handlebars.registerHelper("amount", value => formatAmount(value, locale));
    handlebars.registerHelper("accountingamount", value => formatAmount(value, locale, true));
    handlebars.registerHelper("compactamount", value => formatCompactAmount(value, locale));
    handlebars.registerHelper("irr", value => formatIrr(value, locale, i18n.infinity));
    handlebars.registerHelper("break_even", value => formatBreakEven(value, locale, i18n));
    handlebars.registerHelper("custom_parameter", value =>
        hasValue(value) && (i18n.custom_parameters[value] || value));
    handlebars.registerHelper("dim", value => formatDimension(value, i18n, metadata));
    handlebars.registerHelper("abs", value => Math.abs(value).toString());
    // Handle dates
    handlebars.registerHelper("year", value => formatYear(value, locale));
    handlebars.registerHelper("fiscal-year", value => formatFiscalYear(value, metadata));
    handlebars.registerHelper("year-quarter", value => formatQuarter(value));
    handlebars.registerHelper("year-month", value => formatYearMonth(value, locale));
    handlebars.registerHelper("date", value => formatDate(value, locale));
    handlebars.registerHelper("datetime", value => formatDateTime(value, locale));
    return handlebars;
};

export const formatTemplate = (template, variables, locale, i18n, metadata = {}) => {
    const handlebars = getHandlebars(locale, i18n, metadata);

    // first pass
    const fn1 = handlebars.compile(template);
    const text1 = fn1(variables);

    // second pass
    // the second pass is required because of dynamic dimensions
    const fn2 = handlebars.compile(text1);
    const text2 = fn2(variables);

    return text2;
};

export const formatValue = (value, formatter, locale, i18n) => {
    // HACK: Echarts requires a number, otherwise we can't model a null value.
    // We're using the smallest value for this.
    // On null value, we still want to show a data point, with i18n.chart.label.nan.
    if (value === Number.EPSILON || !hasValue(value)) {
        return i18n.chart.label.nan;
    }

    const template = `{{ ${formatter} var }}`;
    return formatTemplate(template, { var: value }, locale, i18n);
};

export const getCompactFormatter = (values, locale, i18n, isPercentage = false, formatFunction = formatDecimal, noSymbol = false,
    accounting = false, multiplier = "NONE") => {
    if (isPercentage) {
        return value => formatTemplate("{{ percentage var }}", { var: value }, locale, i18n);
    }

    const max = getMaximumValue(values);

    const multiplierValue = getMultiplierValue(max, multiplier);

    const numberOfDecimals = multiplier === "NONE" ? 0 : getNumberOfDecimalsToShow(max, multiplierValue);

    // Use the right locale symbol for the multiplier.
    const symbol = !noSymbol ? getSymbol(values, locale, multiplier) : "";

    return (value, index) => {
        // Red formatting only when the Y axis does not start (or includes) zero.
        if (index === 0 && (value > 0 || max < 0)) {
            return "{min|" + formatFunction(value / multiplierValue, locale, numberOfDecimals) + symbol + "}";
        } else if (accounting && value < 0) {
            return "(" + formatFunction(value / multiplierValue, locale, numberOfDecimals).replace("-", "") + symbol + ")";
        } else {
            return formatFunction(value / multiplierValue, locale, numberOfDecimals) + symbol;
        }
    };
};

/**
 * Get number of decimals to show for a certain value and the corresponding multiplier.
 * 1. Value is the maximum value in a list of values. E.g. maximum value in a report line, excluding YTD.
 * 2. Calculate how many decimals value needs to have 3 significant digits.
 * 3. Apply the resulting number of decimals to all the values in the list.
 * @param value
 * @param multiplier
 * @returns int The number of decimals to show.
 */
export function getNumberOfDecimalsToShow(value, multiplier = getMultiplierValue(value, "AUTO")) {
    const valuePrecision = toPrecisionWithTrailingZeros(value / multiplier, 3);

    const decimalIndex = valuePrecision.indexOf(".");

    return decimalIndex >= 0 ? valuePrecision.toString().length - decimalIndex - 1 : 0;
}

export const getMaximumValue = (values) => {
    let max = 0;
    if (typeof values[0] === "object") {
        // Stacked data
        // Creating sum for each date, using flat would not maintain this division by date
        max = Math.max(max, ...values.map(list => list.reduce((a, b) => a + b, 0)).map(Math.abs));
    } else {
        max = Math.max(max, ...values.map(Math.abs));
    }

    return max;
};

export const getMultiplierValue = (max, multiplier = "AUTO") => {
    switch (multiplier) {
        case "AUTO":
            return [1e12, 1e9, 1e6, 1e3, 1]
                .find(data => Math.abs(max) >= data) || 1;
        case "NONE":
            return 1;
        case "THOUSAND":
            return 1e3;
        case "MILLION":
            return 1e6;
        case "BILLION":
            return 1e9;
        default:
            return 1;
    }
};

export const getSymbol = (values, locale, multiplier = "NONE") => {
    // Multiplier is chosen by the user (e.g. report)
    switch (multiplier) {
        case "NONE":
            return "";
        case "THOUSAND":
            return "K";
        case "MILLION":
            return "M";
        case "BILLION":
            return "B";
    }

    // Multiplier is calculated based on the max value
    const localeFormatter
        = new Intl.NumberFormat(locale.language, {
            notation: "compact",
            compactDisplay: "short",
        });

    const max = getMaximumValue(values);

    return localeFormatter.formatToParts(max)
        .find(el => el.type === "compact")?.value || "";
};
