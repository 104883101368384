import { getForecastingLine, getFormatterFromDateAgg, getTimeSeriesAxisOptions } from "./common";
import { getFormattedValue, getFormatter, getNumericalAxisOptions } from "../common";
import { getChartOptions as getLineChartOptions } from "../line";
import { formatValue } from "../../../util/formatter";

export function getChartOptions(title, statisticalLines, data, config, baseFontSize) {
    // If x type is not date, then we return a line chart, instead of timeline.
    if (data.label_type.x !== "DATE") {
        return getLineChartOptions(title, statisticalLines, data, config, baseFontSize);
    }

    return {
        labelLayout: { hideOverlap: true },
        xAxis: getTimeSeriesAxisOptions(data.labels.x, data.parameters.DATE_AGG, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            trigger: "axis",
            textStyle: { fontSize: 0.4 * baseFontSize },
            formatter: (params) => {
                const [date, value] = params[0].data;

                const dateFormatter = getFormatterFromDateAgg(data.parameters["DATE_AGG"]);
                const valueFormatter = getFormatter(data, "y", config, false);

                const formattedDate = formatValue(date, dateFormatter, config.locale, config.i18n);
                const formattedValue = valueFormatter(value);

                return `${formattedDate} <br/> ${params[0].marker} ${formattedValue}`;
            },
        },
        series: [{
            type: "line",
            data: data.result.map(entry => [entry.x.toDate(), entry.y[0]]),
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(params.data[1], "y", data, config),
            },
            ...getForecastingLine(data, config, baseFontSize),
        }],
    };
}
