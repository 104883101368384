import { getAxisLabel, getCategoricalAxisOptions, getFormattedValue, getFormatter, getLegend, getNumericalAxisOptions, getXData } from "./common";
import { sum } from "lodash";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    const seriesArray = data.series_labels.map((id, index) => ({
        name: id,
        type: "line",
        stack: "y",
        labelLayout: { hideOverlap: true },
        areaStyle: {},
        triggerLineEvent: true,
        symbolSize: 0.15 * baseFontSize,
        data: data.result.map(entry => entry.y[index]),
    }));

    // Display the total of each stack
    // The way it is displayed depends on whether positive or negative values are present
    const allYValues = data.result.map(entry => entry.y.map(value => value)).flat(2);
    if (seriesArray.length === 1 || allYValues.every(value => value <= 0) || allYValues.every(value => value >= 0)) {
        seriesArray[seriesArray.length - 1].label = { // Only show label for the total sum of the stack
            show: true,
            position: "top",
            fontSize: 0.3 * baseFontSize,
            formatter: params => getFormattedValue(sum(data.result[params.dataIndex].y), "y", data, config),
        };
    } else {
        const totalSeries = {
            name: "Total",
            type: "line",
            data: data.result.map(entry => sum(entry.y)),
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(params.value, "y", data, config),
            },
        };
        seriesArray.unshift(totalSeries); // Place this series at the beginning of the array, so that the line has always the same color no matter what
    }

    return {
        xAxis: getCategoricalAxisOptions(data.labels.x, getXData(data, config), config, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            trigger: "axis",
            textStyle: { fontSize: 0.4 * baseFontSize },
            formatter: (params) => {
                const valueFormatter = getFormatter(data, "y", config, false);

                // Start of the tooltip is always the date
                let text = params[0].name;
                params.forEach((series, index) => {
                    if (data.result[series.dataIndex].y[index] != null) {
                        text += `<br/> ${series.marker} ${getAxisLabel(series.seriesName, data, config)} : ${valueFormatter(series.value)}`;
                    }
                });

                return text;
            },
        },
        ...getLegend(data, config, baseFontSize),
        series: seriesArray,
    };
};
