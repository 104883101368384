import React, { createContext, useEffect, useState } from "react";
import theme from "./theme/theme";
import { useSnackbar } from "notistack";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
    AnalysisService,
    AuthenticationService,
    CollectorService,
    ConfigurationService,
    DimensionService,
    ProcurementService,
    QueryService,
    ReportService,
    ScenarioService,
    TryThisService,
    UserService,
} from "./client";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { Requires } from "./util/Requires";
import Layout from "./layout/Layout";
import AskTry from "./sections/AskTry";
import Ask from "./sections/Ask";
import Explore from "./sections/Explore";
import Scenario from "./sections/scenario/Scenario";
import CreateScenario from "./sections/scenario/CreateScenario";
import CloneScenario from "./sections/scenario/CloneScenario";
import EditScenario from "./sections/scenario/EditScenario";
import Report from "./sections/report/Report";
import CloneReport from "./sections/report/CloneReport";
import CreateReport from "./sections/report/CreateReport";
import EditReport from "./sections/report/EditReport";
import ReadReport from "./sections/report/ReadReport";
import Collector from "./sections/collector/Collector";
import CollectorEntry from "./sections/collector/CollectorEntry";
import CollectorEntryItemsEdit from "./sections/collector/CollectorEntryItemsEdit";
import Procurement from "./sections/procurement/Procurement";
import ProjectDetails from "./sections/procurement/ProjectDetails";
import Prioritize from "./sections/procurement/wizard/prioritize/Prioritize";
import Schedule from "./sections/procurement/wizard/Schedule";
import { UserGuideAsk, UserGuideForecast, UserGuideOptimize, UserGuideReport } from "./sections/user_guide/UserGuide";
import ErrorContainer from "./layout/Error";
import CreateCollector from "./sections/collector/CreateCollector";
import EditCollector from "./sections/collector/EditCollector";
import CloneCollector from "./sections/collector/CloneCollector";
import * as Sentry from "@sentry/react";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { getConfig } from "./util/client";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(timezone);
dayjs.tz.setDefault("UTC");

export const AppContext = createContext({});
export const AppRouter = () => {
    const [user, setUser] = useState();
    const [config, setConfig] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const client = {
        auth: AuthenticationService,
        analysis: AnalysisService,
        collector: CollectorService,
        config: ConfigurationService,
        dimension: DimensionService,
        query: QueryService,
        procurement: ProcurementService,
        user: UserService,
        tryThis: TryThisService,
        scenario: ScenarioService,
        report: ReportService,
    };

    useEffect(() => {
        getConfig(client)
            .then((response) => {
                setConfig(response);

                if (response.environment) {
                    Sentry.init({
                        dsn: "https://33cf25601d28f3743615c575e7074742@o4508291612606464.ingest.de.sentry.io/4508330958717008",
                        environment: response.environment,
                        integrations: [
                            Sentry.browserTracingIntegration(),
                            Sentry.replayIntegration(),
                        ],
                        // Tracing
                        tracesSampleRate: 1.0, //  Capture 100% of the transactions
                        // only applied distributed tracing to same URL/api requests.
                        tracePropagationTargets: ["/api/"],
                        // Session Replay
                        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
                    });
                }
            })
            .catch((error) => {
                setConfig(null);
                console.error("Error getting configuration.", error.body?.detail);
            });

        client.auth.authenticationGetProfile()
            .then((response) => {
                setUser(response);
            })
            .catch((error) => {
                setUser(null);
                console.error("Error getting user profile", error.body?.detail);
            });
    }, []);

    // global state store
    const store = {
        user: user,
        client: client,
        config: config,
        notify: {
            error: (error, i18nSnackbarKey) => {
                enqueueSnackbar(config.i18n.error[i18nSnackbarKey] || config.i18n.error[error] || config.i18n.error["unexpected.error"],
                    { variant: "error", preventDuplicate: true });
            },
            warn: (i18nSnackbarKey, ...extra) => {
                if (config.i18n.warn[i18nSnackbarKey]) {
                    enqueueSnackbar(config.i18n.warn[i18nSnackbarKey] + " " + extra, { variant: "warning", preventDuplicate: true });
                }
            },
            info: (i18nSnackbarKey) => {
                if (config.i18n.info[i18nSnackbarKey]) {
                    enqueueSnackbar(config.i18n.info[i18nSnackbarKey], { variant: "default", preventDuplicate: true });
                }
            },
            debug: (message) => {
                if (config.visual?.debug) {
                    enqueueSnackbar(message, { variant: "warning", preventDuplicate: true });
                }
            },
        },
    };

    function getPathFromFeatureFlag(module) {
        switch (module) {
            case "ASK_ME":
                return "/ask";
            case "EXPLORE":
                return "/explore";
            case "FORECAST":
                return "/scenario";
            case "REPORT":
                return "/report";
            case "COLLECTOR":
                return "/collector";
            case "OPTIMIZE":
                return "/procurement";
            case "USER_GUIDE":
                return "/user-guide/ask";
            default:
                return "/ask";
        }
    }

    if (config) {
        return (
            <Router>
                <AppContext.Provider value={store}>
                    <ThemeProvider theme={theme}>
                        <Helmet>
                            <title>{config?.i18n.page.main}</title>
                        </Helmet>
                        <CssBaseline />
                        <QueryParamProvider adapter={ReactRouter6Adapter}>
                            <Layout>
                                <Routes>
                                    <Route
                                        element={(
                                            <Requires flag="ASK_ME">
                                                <AskTry />
                                            </Requires>
                                        )}
                                        path="/ask"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="ASK_ME">
                                                <Ask />
                                            </Requires>
                                        )}
                                        path="/ask/:analysisId"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="EXPLORE">
                                                <Explore />
                                            </Requires>
                                        )}
                                        path="/explore"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="FORECAST">
                                                <Scenario />
                                            </Requires>
                                        )}
                                        path="/scenario"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="FORECAST">
                                                <CreateScenario />
                                            </Requires>
                                        )}
                                        path="/scenario/create"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="FORECAST">
                                                <CloneScenario />
                                            </Requires>
                                        )}
                                        path="/scenario/:scenarioId/clone"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="FORECAST">
                                                <EditScenario />
                                            </Requires>
                                        )}
                                        path="/scenario/:scenarioId/edit"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="REPORT">
                                                <Report />
                                            </Requires>
                                        )}
                                        path="/report"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="REPORT">
                                                <CloneReport />
                                            </Requires>
                                        )}
                                        path="/report/:reportId/clone"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="REPORT">
                                                <CreateReport />
                                            </Requires>
                                        )}
                                        path="/report/create"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="REPORT">
                                                <EditReport />
                                            </Requires>
                                        )}
                                        path="/report/:reportId/edit"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="REPORT">
                                                <ReadReport />
                                            </Requires>
                                        )}
                                        path="/report/:reportId"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <Collector />
                                            </Requires>
                                        )}
                                        path="/collector"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <CreateCollector />
                                            </Requires>
                                        )}
                                        path="/collector/create"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <CollectorEntry />
                                            </Requires>
                                        )}
                                        path="/collector/:collectorId"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <CollectorEntryItemsEdit />
                                            </Requires>
                                        )}
                                        path="/collector/:collectorId/:entryId"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <EditCollector />
                                            </Requires>
                                        )}
                                        path="/collector/:collectorId/edit"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <CloneCollector />
                                            </Requires>
                                        )}
                                        path="/collector/:collectorId/clone"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="OPTIMIZE">
                                                <Procurement />
                                            </Requires>
                                        )}
                                        path="/procurement"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="OPTIMIZE">
                                                <ProjectDetails operation="create" />
                                            </Requires>
                                        )}
                                        path="/procurement/project/create"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="OPTIMIZE">
                                                <ProjectDetails operation="edit" />
                                            </Requires>
                                        )}
                                        path="/procurement/project/:projectId/edit"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="OPTIMIZE">
                                                    <Prioritize
                                                        steps={["prioritize", "schedule"]}
                                                        originPath="/procurement"
                                                        basePath="/procurement/project/:projectId"
                                                    />
                                            </Requires>
                                        )}
                                        path="/procurement/project/:projectId/prioritize"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="OPTIMIZE">
                                                    <Schedule
                                                        steps={["prioritize", "schedule"]}
                                                        originPath="/procurement"
                                                        basePath="/procurement/project/:projectId"
                                                    />
                                            </Requires>
                                        )}
                                        path="/procurement/project/:projectId/schedule"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="USER_GUIDE">
                                                <Requires flag="ASK_ME">
                                                    <UserGuideAsk />
                                                </Requires>
                                            </Requires>
                                        )}
                                        path="/user-guide/ask"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="USER_GUIDE">
                                                <Requires flag="REPORT">
                                                    <UserGuideReport />
                                                </Requires>
                                            </Requires>
                                        )}
                                        path="/user-guide/report"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="USER_GUIDE">
                                                <Requires flag="FORECAST">
                                                    <UserGuideForecast />
                                                </Requires>
                                            </Requires>
                                        )}
                                        path="/user-guide/forecast"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="USER_GUIDE">
                                                <Requires flag="OPTIMIZE">
                                                    <UserGuideOptimize />
                                                </Requires>
                                            </Requires>
                                        )}
                                        path="/user-guide/optimize"
                                    />
                                    <Route
                                        element={(
                                            <ErrorContainer />
                                        )}
                                        path="/error"
                                    />
                                    <Route
                                        path="/"
                                        element={<Navigate to={getPathFromFeatureFlag(config.feature_flag.modules[0])} />}
                                    />
                                </Routes>
                            </Layout>
                        </QueryParamProvider>
                    </ThemeProvider>
                </AppContext.Provider>
            </Router>
        );
    }
};
