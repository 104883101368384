import { formatCompact, formatCompactInteger, formatTemplate, formatValue, getCompactFormatter } from "../../util/formatter";
import theme from "../../theme/theme";
import { max, sum } from "lodash";

export const defaultFontColor = theme.palette.grey["600"];

export const getXData = (data, config) => {
    return data.result.map((el) => {
        const value = el.label;
        // if there is a template, apply it
        if (data.metadata[value]?.template) {
            return formatTemplate(data.metadata[value]?.template, {}, config.locale, config.i18n);
        }

        // lookup short name, name, i18n key or default to the value itself
        return getAxisLabel(value, data, config);
    });
};

export const getTitleOptions = (title, baseFontSize) => {
    return {
        text: title || "",
        left: "center",
        textStyle: {
            fontSize: 0.5 * baseFontSize,
            fontWeight: "normal",
        },
    };
};

export const getTextStyleOptions = () => {
    return {
        fontFamily: "Roboto",
        color: defaultFontColor,
    };
};

export const getGridOptions = (baseFontSize) => {
    return {
        top: 1.5 * baseFontSize,
        bottom: 2.0 * baseFontSize,
        left: 2.6 * baseFontSize,
        right: 1 * baseFontSize,
        containLabel: true,
    };
};

export const getCategoricalAxisOptions = (name, data, config, baseFontSize, extraColumns) => {
    return {
        data: data,
        name: name,
        nameLocation: "middle",
        nameGap: 1.5 * baseFontSize,
        nameTextStyle: {
            fontSize: 0.5 * baseFontSize,
        },
        type: "category",
        axisLabel: {
            interval: 0, // TODO dynamic interval
            rotate: 0,
            width: baseFontSize * (15 / (data.length + (extraColumns || 0))),
            height: 2 * 0.35 * baseFontSize,
            margin: 0.25 * baseFontSize,
            overflow: "breakAll",
            lineOverflow: "truncate", // TODO this currently requires an echarts patch
            fontSize: defaultLabelFontSize(baseFontSize),
            lineHeight: 0.35 * baseFontSize,
        },
    };
};

export const getNumericalAxisOptions = (name, data, config, label_type, baseFontSize) => {
    return {
        name: name,
        nameLocation: "middle",
        nameGap: 1.5 * baseFontSize,
        nameTextStyle: {
            fontSize: 0.5 * baseFontSize,
        },
        type: "value",
        axisLabel: {
            fontSize: defaultLabelFontSize(baseFontSize),
            formatter: getCompactFormatter(data, config.locale, config.i18n, label_type === "PERCENTAGE", formatCompact),
            rich: {
                min: {
                    color: theme.palette.error.main,
                    borderWidth: 1,
                    borderColor: theme.palette.error.main,
                    borderRadius: 5,
                    borderType: "dotted",
                    padding: 5,
                    fontSize: 0.38 * baseFontSize,
                },
            },
        },
    };
};

export const getTooltip = (data, config, baseFontSize, extraOptions) => {
    return {
        tooltip: {
            confine: true,
            textStyle: {
                fontSize: 0.4 * baseFontSize,
            },
            ...extraOptions,
        },
    };
};

export const getBaseTooltip = (data, config, baseFontSize, extraOptions) => {
    return {
        ...getTooltip(data, config, baseFontSize, {
            valueFormatter: getFormatter(data, "y", config, false), // not compacted
            ...extraOptions,
        }),
    };
};

export const getAxisTooltip = (data, config, baseFontSize, extraOptions = {}) => {
    return {
        ...getBaseTooltip(data, config, baseFontSize, {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
            formatter: (params) => {
                if (params.length === 2 && params[0].seriesName === "transparent") {
                    // Waterfall with a hidden series' tooltip
                    const name = formatTooltipValue(data, "x", getTooltipLabel(params[1].data.id, data, config), config);
                    const value = params[1].data.positive ? params[1].value : -params[1].value;

                    return `${name} <br/> ${params[1].marker}${formatTooltipValue(data, "y", value, config)}`;
                } else {
                    const title = formatTooltipValue(data, "x", getTooltipLabel(params[0].data.id, data, config), config);

                    return getAxisTooltipFormatter(data, config, title, value => value.value)(params);
                }
            },
            ...extraOptions,
        }),
    };
};

export function getAxisTooltipFormatter(data, config, title, valueFetcher) {
    return (params) => {
        let returnVal = title;
        if (params.length === 1) {
            // Single series' tooltip
            returnVal += ` <br/> ${params[0].marker}`;

            // if there isn't value, format it with the special label of EPSILON - NA.
            // this is useful on line charts, with null values.
            let value = valueFetcher(params[0]);
            if (value === null) {
                value = Number.EPSILON;
            }

            returnVal += formatTooltipValue(data, "y", value, config);
        } else {
            params.forEach((values, index) => {
                const dimIndex = params.indexOf(values);
                const id = data.series_labels[index];
                const name = getTooltipLabel(id, data, config);
                if (data.result[values.dataIndex].y[dimIndex] != null) {
                    returnVal += `<br/> ${values.marker} ${name} : `;
                    returnVal += formatTooltipValue(data, "y", valueFetcher(values), config);
                }
            });
        }
        return returnVal;
    };
}

export const getItemTooltip = (data, config, baseFontSize) => {
    return {
        ...getBaseTooltip(data, config, baseFontSize, {
            trigger: "item",
        }),
    };
};

export const getPieChartData = (data, config) => {
    const ySeries = [...Array(data.result[0].y.length)].map(() => []);
    // [[...y1], [...y2], ...]
    data.result.forEach((result) => {
        for (let i = 0; i < result.y.length; i++) {
            ySeries[i].push({
                id: result.label,
                name: getAxisLabel(result.label, data, config),
                // HACK: Echarts requires a number, otherwise we can't model a null value.
                // We're using the smallest value for this.
                // On null value, we still want to show a data point, with a special label.
                value: result.y[i] !== null ? result.y[i] : Number.EPSILON,
            });
        }
    });

    return ySeries;
};

export const getYSeriesData = (data, idFormatter = id => id) => {
    // [] * number of series.
    const ySeries = [...Array(data.result[0].y.length)].map(() => []);
    // [[...y1], [...y2], ...]
    data.result.forEach((result) => {
        for (let i = 0; i < result.y.length; i++) {
            ySeries[i].push({
                id: idFormatter(result.label),
                name: idFormatter(result.label),
                // HACK: Echarts requires a number, otherwise we can't model a null value.
                // We're using the smallest value for this.
                // On null value, we still want to show a data point, with a special label.
                value: result.y[i] !== null ? result.y[i] : Number.EPSILON,
            });
        }
    });

    return ySeries;
};

export const getYLineSeriesData = (data) => {
    // [] * number of series.
    const ySeries = [...Array(data.result[0].y.length)].map(() => []);
    // [[...y1], [...y2], ...]
    data.result.forEach((result) => {
        for (let i = 0; i < result.y.length; i++) {
            ySeries[i].push({
                id: result.label,
                name: result.label,
                // In line, is Ok to pass null, so it can connectNulls.
                value: result.y[i],
            });
        }
    });

    return ySeries;
};

export const getYSeries = (data, type, baseFontSize, config, includeLabel, extraOptions = {}) => {
    // We want to get the highest column to use it as a scale to display the labels
    const totals = data.result.map(entry => sum(entry.y));
    const maxTotal = max(totals);

    return {
        series: getYSeriesData(data).map((series, index) => {
            let labelOptions = {};

            if (includeLabel) {
                labelOptions = {
                    label: {
                        ...defaultValueLabelSetting("inside", baseFontSize, config, value =>
                            // Only render the label if the value is more than 3% of highest column
                            Math.abs(value.data.value / maxTotal) > 0.03 ? formatCompactInteger(value.data.value, config.locale) : "",
                        ),
                        fontSize: 0.25 * baseFontSize, // Reduce size to fit better inside smaller columns,
                    },
                };
            }

            return ({
                name: index < data?.series_labels.length ? data.series_labels[index] : null,
                type: type,
                stack: "y",
                data: series,
                labelLayout: { hideOverlap: true },
                ...labelOptions,
                ...extraOptions,
            });
        }),
    };
};

export const getLegend = (data, config, baseFontSize) =>
    ({
        width: "64%",
        legend: {
            selectedMode: false,
            orient: "vertical",
            top: "middle",
            left: "78%", // relative to container width. 78% is just enough next to the slide.
            icon: "roundRect",
            itemGap: 0.2 * baseFontSize,
            itemWidth: 0.4 * baseFontSize,
            itemHeight: 0.4 * baseFontSize,
            textStyle: {
                fontSize: 0.38 * baseFontSize,
                color: defaultFontColor,
                overflow: "truncate",
            },
            formatter: function (id) {
                return getAxisLabel(id, data, config);
            },
        },
    });

export const getAxisLabel = (id, data, config) => {
    if (id === null) {
        return config.i18n.chart.label["__null__"];
    }

    return data?.metadata[id]?.short_name
        || data?.metadata[id]?.name
        || config.i18n.chart.label[id]
        || config.i18n.Document[id]
        || config.i18n.AccountingTreatment[id]
        || id;
};

export const getTooltipLabel = (id, data, config) => {
    if (id === null) {
        return config.i18n.chart.label["__null__"];
    }

    return data.metadata[id]?.name
        || data.metadata[id]?.short_name
        || config.i18n.chart.label[id]
        || config.i18n.Document[id]
        || config.i18n.AccountingTreatment[id]
        || id;
};

export const getMaxLabelLength = (data, config) => {
    const labels = data.result.map(el => getAxisLabel(el.x, data, config));
    const lengths = labels.map(el => el.length);
    return Math.max(...lengths);
};

export function defaultValueLabelSetting(
    position,
    baseFontSize,
    config,
    formatter,
) {
    return {
        show: true,
        position: position,
        fontSize: 0.3 * baseFontSize,
        formatter: formatter,
    };
}

// TODO delete function and refactor
export function formatTooltipValue(data, axis, value, config) {
    return getFormatter(data, axis, config, false)(value);
}

export function getFormatterFromLabelType(labelType, compact = false, accounting = false, report = false) {
    switch (labelType) {
        case "INTEGER":
            return compact ? "compactinteger" : "integer";
        case "DECIMAL":
            return compact ? "compact" : "decimal";
        case "AMOUNT":
            return accounting ? "accountingamount" : (compact ? "compactamount" : "amount");
        case "DATE":
            return "date";
        case "PERCENTAGE":
            return report ? "reportpercentage" : "percentage";
        default:
            return null;
    }
}

export function getFormattedValue(val, label, data, config, labelType = null, compact = true, accounting = false, report = false) {
    return getFormatter(data, label, config, compact, accounting, labelType, report)(val);
}

export function getFormatter(data, label, config, compact = true, accounting = false, labelType = null, report = false) {
    const formatterType = getFormatterFromLabelType(labelType ? labelType[label] : data.label_type[label], compact, accounting, report);

    if (formatterType) {
        return value => formatValue(value, formatterType, config.locale, config.i18n);
    } else {
        return value => value;
    }
}

// Used in waterfall charts. Pick bar colors for extremes, positive and negative (values)
export function getWaterfallSeriesColors(value, series, charType) {
    // Extreme columns should have a different color when they represent a cumulative value (expenses, profit, total).
    // The last column is always a cumulative value in waterfall charts. The first only in variance, e.g. (first: expenses, last: spend)
    if ((value.dataIndex === 0 && charType === "VARIANCE") || value.dataIndex === series.length - 1) {
        return theme.palette.echartsBlue.main;
    } else if (value.data.value >= 0) {
        return theme.palette.echartsLightGreen.main;
    } else {
        return theme.palette.echartsRed.main;
    }
}

export function getLineColor(config, param) {
    // FIXME 2868: change this to use color scheme colors
    switch (param) {
        case "MEDIAN":
            return theme.palette.echartsYellow.main;
        case "P75":
            return theme.palette.echartsOrange.main;
        case "AVERAGE":
            return theme.palette.echartsGreen.main;
        case "P25":
            return theme.palette.echartsRed.main;
    }
}

export function displayStatLines(statLinesParam, statLinesValue, config) {
    return statLinesParam
        // ignore params not present in the calculated value.
        .filter(param => statLinesValue && param in statLinesValue)
        .map((param) => {
            return {
                name: config.i18n.chart.lines[param],
                yAxis: statLinesValue[param],
                lineStyle: {
                    color: getLineColor(config, param),
                },
            };
        },
        );
}

export const defaultLabelFontSize = (baseFontSize) => {
    return 0.35 * baseFontSize;
};
