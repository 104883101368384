import React, { useContext } from "react";
import PropTypes from "prop-types";
import ValueField from "./field/ValueField";
import { AppContext } from "../AppRouter";

const CustomParameterSelector = ({ definition, values, disabled, onCustomParameterUpdate, sx }) => {
    const { config } = useContext(AppContext);

    if (definition) {
        return definition.map(parameter =>
            (
                <ValueField
                    key={parameter.name + "-custom"}
                    dataCyProp={parameter.name + "-custom"}
                    value={values[parameter.name] != null ? values[parameter.name] : parameter.value}
                    // only used when type == select.
                    possibleValues={parameter.options}
                    label={config.i18n.custom_parameters[parameter.name] || parameter.name}
                    type={parameter.value_type}
                    disabled={disabled}
                    metadata={config.i18n.custom_parameters} // enables translation of select options
                    sx={sx}
                    onChange={(value) => {
                        const newCustomValues = { ...values, [parameter.name]: value };
                        onCustomParameterUpdate(newCustomValues);
                    }}
                />
            ));
    } else {
        return null;
    }
};

CustomParameterSelector.propTypes = {
    definition: PropTypes.array,
    values: PropTypes.object,
    disabled: PropTypes.bool,
    onCustomParameterUpdate: PropTypes.func,
    sx: PropTypes.object,
};

export default CustomParameterSelector;
