import { getAxisLabel, getCategoricalAxisOptions, getFormattedValue, getFormatter, getLegend, getNumericalAxisOptions, getXData } from "./common";
import { max, sum } from "lodash";
import { getChartOptions as getStackedTimelineColumnOptions } from "./timeline/stackedTimelineColumn";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    if (data.label_type.x === "DATE") {
        return getStackedTimelineColumnOptions(title, statisticalLines, data, config, baseFontSize);
    } else {
        // We want to get the highest column to use it as a scale to display the labels of each block
        const columnTotals = data.result.map(entry => sum(entry.y));
        const maxColumnTotal = max(columnTotals);

        const seriesArray = data.series_labels.map((id, index) => ({
            name: id,
            type: "bar",
            stack: "y",
            triggerLineEvent: true,
            symbolSize: 0.15 * baseFontSize,
            data: data.result.map(entry => entry.y[index]),
            label: data.result.length <= 12 && data.series_labels.length > 1 ? {
                show: true,
                position: "inside",
                fontSize: 0.25 * baseFontSize,
                formatter: params => Math.abs(params.data / maxColumnTotal) > 0.03 ? getFormattedValue(params.data, "y", data, config) : "",
            } : {},
        }));

        // Display the total of each stack
        // The way it is displayed depends on whether positive or negative values are present
        const allYValues = data.result.map(entry => entry.y.map(value => value)).flat(2);
        let totalSeries;
        if (seriesArray.length === 1 || allYValues.every(value => value <= 0) || allYValues.every(value => value >= 0)) {
            totalSeries = { // All values are positive, so simply display the total label at the top of the stacked column
                type: "bar",
                stack: "y",
                data: data.result.map((entry) => {
                    const total = sum(entry.y);
                    return {
                        value: total >= 0 ? Number.EPSILON : -Number.EPSILON,
                        label: { position: total >= 0 ? "top" : "bottom" },
                    };
                }),
                label: {
                    show: true,
                    fontSize: 0.3 * baseFontSize,
                    formatter: params => getFormattedValue(sum(data.result[params.dataIndex].y), "y", data, config),
                },
            };
            seriesArray.push(totalSeries);
        } else {
            totalSeries = { // Display a line as the sum of at each stacked column
                name: "Total",
                type: "line",
                data: data.result.map(entry => sum(entry.y)),
                label: {
                    show: true,
                    position: "top",
                    fontSize: 0.3 * baseFontSize,
                    formatter: params => getFormattedValue(params.data, "y", data, config),
                },
            };
            seriesArray.unshift(totalSeries); // Place this series at the beginning of the array, so that the line has always the same color no matter what
        }

        return {
            xAxis: getCategoricalAxisOptions(data.labels.x, getXData(data, config), config, baseFontSize),
            yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
            ...getLegend(data, config, baseFontSize),
            tooltip: {
                trigger: "axis",
                textStyle: { fontSize: 0.4 * baseFontSize },
                formatter: (params) => {
                    const valueFormatter = getFormatter(data, "y", config, false);

                    // Start of the tooltip is always the date
                    let text = params[0].name;
                    params.forEach((series) => {
                        if (Math.abs(series.value) !== Number.EPSILON) {
                            text += `<br/> ${series.marker} ${getAxisLabel(series.seriesName, data, config)} : ${valueFormatter(series.value)}`;
                        }
                    });

                    return text;
                },
            },
            series: seriesArray,
        };
    }
};
