import { getChartOptions as getTimelineCompare } from "./timelineCompare";
import { getAxisLabel, getCategoricalAxisOptions, getFormattedValue, getFormatter, getLegend, getNumericalAxisOptions, getXData } from "../common";

export function getChartOptions(title, statisticalLines, data, config, baseFontSize) {
    // If x type is not date, then we return a line chart, instead of timeline.
    if (data.label_type.x !== "DATE") {
        return {
            xAxis: getCategoricalAxisOptions(data.labels.x, getXData(data, config), config, baseFontSize),
            yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
            tooltip: {
                trigger: "axis",
                textStyle: { fontSize: 0.4 * baseFontSize },
                formatter: (params) => {
                    let tooltipText = params[0].axisValue;
                    const valueFormatter = getFormatter(data, "y", config, false);

                    params.forEach((series) => {
                        tooltipText += series.data !== Number.EPSILON ? `<br/>${series.marker} ${series.seriesName} : ${valueFormatter(series.data)}` : "";
                    });

                    return tooltipText;
                },
            },
            ...getLegend(data, config, baseFontSize),
            labelLayout: { hideOverlap: true },
            series: data.series_labels.map((label, index) => ({
                type: "line",
                name: getAxisLabel(label, data, config),
                data: data.result.map(entry => entry.y[index]),
                label: {
                    show: true,
                    fontSize: 0.3 * baseFontSize,
                    formatter: params => getFormattedValue(params.data, "y", data, config),
                },
            })),
        };
    } else {
        return getTimelineCompare(title, statisticalLines, data, config, baseFontSize);
    }
}
