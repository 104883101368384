import dayjs from "dayjs";
import {
    defaultFontColor,
    defaultLabelFontSize,
    defaultValueLabelSetting,
    getAxisTooltip as getBaseAxisTooltip,
    getAxisTooltipFormatter,
} from "../common";
import { formatCompactInteger, formatQuarter, formatValue } from "../../../util/formatter";
import { isForecastingEnabled } from "../../../util/feature_flag_util";
import { max, sum } from "lodash";
import { Dimension } from "../../../client";

const axisLabelFormatterQuarter = (value) => {
    // Formatted to be month/day; display year only in the first label
    const date = dayjs.utc(value);
    if (date.day() === 1 && date.month() % 3 === 0) {
        return formatQuarter(value);
    }
    return "";
};

const axisLabelFormatterMonth = () => {
    // Formatted to be month/day; display year only in the first label
    return {
        year: "{monthStyle|{MMM}'{yy}}", // So that january doesn't go empty
        month: "{monthStyle|{MMM}'{yy}}",
    };
};

const axisLabelFormatterYear = () => {
    return {
        year: "{yearStyle|{yyyy}}",
    };
};

export const getTimeSeriesAxisOptions = (name, date_agg, baseFontSize) => {
    let axisLabelFormatter;
    switch (date_agg) {
        case Dimension.DATE_YEAR:
            axisLabelFormatter = axisLabelFormatterYear();
            break;
        case Dimension.DATE_YEAR_QUARTER:
            axisLabelFormatter = axisLabelFormatterQuarter;
            break;
        case Dimension.DATE_YEAR_MONTH:
            axisLabelFormatter = axisLabelFormatterMonth();
            break;
        default:
            throw new Error(`No formatter for DATE_AGG '${date_agg}'.`);
    }

    return {
        name: name,
        nameLocation: "middle",
        nameGap: 1.5 * baseFontSize,
        nameTextStyle: {
            fontSize: 0.5 * baseFontSize,
        },
        type: "time",
        minInterval: 3600 * 24 * 1000 * (date_agg === "DATE_YEAR" ? 355 : 30),
        max: "dataMax",
        axisLabel: {
            formatter: axisLabelFormatter,
            rich: {
                yearStyle: {
                    fontSize: defaultLabelFontSize(baseFontSize),
                    color: defaultFontColor,
                },
                monthStyle: {
                    fontSize: defaultLabelFontSize(baseFontSize),
                    color: defaultFontColor,
                },
            },
            showMinLabel: true,
            showMaxLabel: true,
        },
    };
};

export function getYSeriesData(data) {
    // [] * number of series.
    const ySeries = [...Array(data.result[0].y.length)].map(() => []);
    // [[...y1], [...y2], ...]
    data.result.forEach((result) => {
        for (let i = 0; i < result.y.length; i++) {
            // time axis require value to be a 2 position array.
            // 0 - time
            // 1 - value
            const label = (result.label || result.x).toDate();
            ySeries[i].push({
                id: label,
                name: label,
                value: [label, result.y[i]],
            });
        }
    });

    return ySeries;
}

export function getYSeries(data, type, baseFontSize, config, includeLabel, extraOptions = {}) {
    // We want to get the highest column to use it as a scale to display the labels
    const totals = data.result.map(entry => sum(entry.y));
    const maxTotal = max(totals);

    return {
        series: getYSeriesData(data)
            .map((series, index) => {
                let labelOptions = {};
                if (includeLabel && series.length <= 12) { // Labels become too big if there are more than 12 columns
                    labelOptions = {
                        label: {
                            ...defaultValueLabelSetting("inside", baseFontSize, config, value =>
                                // Only render the label if the value is more than 3% of highest column
                                Math.abs(value.data.value[1] / maxTotal) > 0.03 ? formatCompactInteger(value.data.value[1], config.locale) : "",
                            ),
                            fontSize: 0.25 * baseFontSize, // Reduce size to fit better inside smaller columns,
                        },
                    };
                }

                return ({
                    name: index < data?.series_labels.length ? data.series_labels[index] : "", // FIXME this should not happen!
                    type: type,
                    stack: "y",
                    data: series,
                    labelLayout: { hideOverlap: true },
                    symbolSize: 0.15 * baseFontSize,
                    ...labelOptions,
                    ...extraOptions,
                });
            }),
    };
}

export function getAxisTooltip(data, config, baseFontSize) {
    return {
        ...getBaseAxisTooltip(data, config, baseFontSize, {
            formatter: (params) => {
                const time = params[0].data.id;
                const formatter = getFormatterFromDateAgg(data.parameters["DATE_AGG"]);
                const title = formatValue(time, formatter, config.locale, config.i18n);

                return getAxisTooltipFormatter(data, config, title, value => value.data.value[1])(params);
            },
        }),
    };
}

export function getForecastingLine(data, config, baseFontSize) {
    const isForecasting = isForecastingEnabled(config) && data.parameters?.SCENARIO != null;

    if (!isForecasting) {
        return {};
    }

    const cutoffDate = dayjs.utc(data.parameters?.CUTOFF_DATE || config.time.forecasting?.min);

    // Forecasting line is placed between the last historical value and the first forecasted value
    const firstForecastEntry = data.result.find(value => cutoffDate.isSame(value.x, "month") || cutoffDate.isBefore(value.x, "month"));

    if (!firstForecastEntry) {
        return {};
    }

    let cutoffLineDate = firstForecastEntry.x;

    // The middle between two date points in the chart depends on date aggregation
    switch (data.parameters.DATE_AGG) {
        case "DATE_YEAR":
            cutoffLineDate = cutoffLineDate.subtract(6, "month");
            break;
        case "DATE_YEAR_QUARTER":
            cutoffLineDate = cutoffLineDate.subtract(45, "day");
            break;
        case "DATE_YEAR_MONTH":
            cutoffLineDate = cutoffLineDate.subtract(15, "day");
            break;
    }

    return {
        markLine: {
            symbol: "none",
            animation: false,
            data: [{
                xAxis: cutoffLineDate.toDate(),
                lineStyle: { width: 2, color: "grey" },
                label: {
                    formatter: config.i18n.chart.lines.actual,
                    fontSize: defaultLabelFontSize(baseFontSize),
                    position: "insideEndTop",
                },
            },
            {
                xAxis: cutoffLineDate.toDate(),
                lineStyle: { width: 2, color: "grey" },
                label: {
                    formatter: config.i18n.chart.lines.forecast,
                    fontSize: defaultLabelFontSize(baseFontSize),
                    position: "insideEndBottom",
                },
            }],
        },
    };
}

export function getFormatterFromDateAgg(dateAgg) {
    switch (dateAgg) {
        case "DATE_YEAR":
            return "year";
        case "DATE_YEAR_MONTH":
            return "year-month";
        case "DATE_YEAR_QUARTER":
            return "year-quarter";
        default:
            throw new Error("DATE_AGG not supported for formatting.");
    }
}
