import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ScenarioDetails from "./ScenarioDetails";
import SaveIcon from "@mui/icons-material/Save";
import { AppContext } from "../../AppRouter";
import { isValidScenario } from "../../util/scenario";

const CloneScenario = () => {
    const { client, config, notify } = useContext(AppContext);

    const [shouldValidate, setShouldValidate] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { scenarioId } = useParams();

    const cloneScenario = (name, series) => {
        setShouldValidate(true);
        if (scenarioId && isValidScenario(name, series)) {
            setLoading(true);
            client.scenario.scenarioCloneScenario(scenarioId,
                {
                    name: name,
                    series: series,
                })
                .then(() => {
                    setLoading(false);
                    navigate("/scenario");
                })
                .catch((error) => {
                    setLoading(false);
                    notify.error(error, "scenario.clone");
                });
        }
    };

    return (
        <ScenarioDetails
            operation="clone"
            title={config.i18n.scenario.clone}
            onClick={cloneScenario}
            shouldValidate={shouldValidate}
            waiting={loading}
            icon={<SaveIcon />}
            button={config?.i18n.button.save}
        />
    );
};

export default CloneScenario;
