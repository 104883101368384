import Slide from "../../../../components/Slide";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getDefaultByType, initializeParameterDefaults } from "../../../../util/parameters";
import { AppContext } from "../../../../AppRouter";
import { runAnalysis } from "../../../../util/client";

const RefineMetric = ({
    externalLoading,
    analysisId,
    analysisParameters,
    filtersInParameters,
    filtersOutParameters,
    customParameters,
}) => {
    const { client, notify } = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [analysis, setAnalysis] = useState();
    const [analysisContext, setAnalysisContext] = useState();

    useEffect(() => {
        if (analysisId) {
            setLoading(true);

            client.analysis.analysisGet(analysisId)
                .then((analysis) => {
                    setAnalysis(analysis);
                })
                .catch((error) => {
                    setAnalysis(null);
                    setLoading(false);
                    notify.error(error, "analysis.fetch");
                });
        } else {
            setAnalysis(null);
        }
    }, [analysisId]);

    useEffect(() => {
        if (analysis && analysisParameters && customParameters) {
            setLoading(true);

            runAnalysis(
                client,
                analysis.id,
                {
                    ...initializeParameterDefaults(analysis, ["CHART", "DIMENSION", "HIERARCHY"]),
                    ...analysisParameters,
                },
                filtersInParameters,
                filtersOutParameters,
                customParameters,
            ).then((data) => {
                setAnalysisContext({
                    question: analysis.question,
                    chart: getDefaultByType(analysis.customization_parameters, "CHART"),
                    data: data,
                });
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                notify.error(error, "analysis.run");
            });
        }
    }, [analysis, analysisParameters, filtersInParameters, filtersOutParameters, customParameters]);

    return (
        <Slide context={analysisContext} chart={analysisContext?.chart} loading={externalLoading || loading} hideLinks={true} />
    );
};

RefineMetric.propTypes = {
    externalLoading: PropTypes.bool,
    analysisId: PropTypes.string,
    analysisParameters: PropTypes.object,
    filtersInParameters: PropTypes.object,
    filtersOutParameters: PropTypes.object,
    customParameters: PropTypes.object,
};

RefineMetric.defaultValues = {
    analysisParameters: {},
    filtersInParameters: {},
    filtersOutParameters: {},
    customParameters: {},
};

export default RefineMetric;
