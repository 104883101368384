import { defaultLabelFontSize, displayStatLines, getCategoricalAxisOptions, getFormattedValue, getFormatter, getNumericalAxisOptions, getXData } from "./common";
import { getChartOptions as getTimelineColumnOptions } from "./timeline/timelineColumn";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    // Column chart can have both time axis and categorical axis
    if (data.label_type.x === "DATE") {
        return getTimelineColumnOptions(title, statisticalLines, data, config, baseFontSize);
    } else {
        return {
            xAxis: getCategoricalAxisOptions(data.labels.x, getXData(data, config), config, baseFontSize),
            yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
            tooltip: {
                confine: true,
                textStyle: { fontSize: 0.4 * baseFontSize },
                axisPointer: { type: "shadow" },
                formatter: (params) => {
                    const formatter = getFormatter(data, "y", config, false);
                    return `${params.name} <br/> ${params.marker} ${formatter(params.value)}`;
                },
            },
            series: [{
                type: "bar",
                data: data.result.map(entry => entry.y[0]),
                label: {
                    show: true,
                    position: "top",
                    fontSize: 0.3 * baseFontSize,
                    formatter: params => getFormattedValue(params.data, "y", data, config),
                },
                markLine: { // Categorical results may have statistical lines
                    data: statisticalLines && data.variables ? displayStatLines(statisticalLines, data.variables, config) : [],
                    symbol: "none",
                    silent: true,
                    animation: false,
                    lineStyle: { width: 2 }, // this style will be applied to all lines
                    label: {
                        fontSize: defaultLabelFontSize(baseFontSize),
                        formatter: function (params) {
                            return params.name + " : " + getFormattedValue(params.data.yAxis, "y", data, config);
                        },
                        position: "insideEndTop",
                    },
                },
            }],
        };
    }
};
