import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ScenarioDetails from "./ScenarioDetails";
import SaveIcon from "@mui/icons-material/Save";
import { AppContext } from "../../AppRouter";
import { isValidScenario } from "../../util/scenario";

const EditScenario = () => {
    const { client, config, notify } = useContext(AppContext);

    const [shouldValidate, setShouldValidate] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const navigate = useNavigate();

    const { scenarioId } = useParams();

    const editScenario = (name, series) => {
        setShouldValidate(true);
        if (scenarioId && isValidScenario(name, series)) {
            setWaiting(true);
            client.scenario.scenarioUpdateScenario(scenarioId,
                {
                    name: name,
                    series: series,
                })
                .then(() => {
                    setWaiting(false);
                    navigate(`/scenario`);
                })
                .catch((error) => {
                    setWaiting(false);
                    notify.error(error, "scenario.edit");
                });
        }
    };

    return (
        <ScenarioDetails
            operation="edit"
            title="Edit scenario"
            onClick={editScenario}
            shouldValidate={shouldValidate}
            waiting={waiting}
            icon={<SaveIcon />}
            button={config?.i18n.button.save}
        />
    );
};

export default EditScenario;
