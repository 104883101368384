import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import AdvisorContainer from "../../layout/AdvisorContainer";
import SelectField from "../../components/field/SelectField";
import { Link as RouterLink, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { isForecastingEnabled } from "../../util/feature_flag_util";
import ClickableDatePicker from "../../components/ClickableDatePicker";
import DimensionSelector from "../../components/selector/DimensionSelector";
import { isEmpty, union, without } from "lodash";
import Markdown from "markdown-to-jsx";
import { AppContext } from "../../AppRouter";
import { getReport } from "../../util/client";

const ReportDetails = ({ operation, title, button, icon, shouldValidate, loading, fetchReport, filtersDisabled, onClick }) => {
    const { client, config, notify, user } = useContext(AppContext);

    const [name, setName] = useState("");
    const [templateOptions, setTemplateOptions] = useState(null);
    const [template, setTemplate] = useState(null);
    const [scenarioOptions, setScenarioOptions] = useState(null);
    const [scenario, setScenario] = useState(null);

    const [multiplier, setMultiplier] = useState("AUTO");
    const multiplierOptions = config.i18n.report.multiplier.options;

    const [hideEmptyRows, setHideEmptyRows] = useState(false);
    const [hideInitialMonths, setHideInitialMonths] = useState(false);
    const [documentDimensions, setDocumentDimensions] = useState({});
    const [filtersInDimensions, setFiltersInDimensions] = useState([]);
    const [filtersInParameters, setFiltersInParameters] = useState({});
    const [filtersOutDimensions, setFiltersOutDimensions] = useState([]);
    const [filtersOutParameters, setFiltersOutParameters] = useState({});
    const [reportDimensionsMetadata, setReportDimensionsMetadata] = useState({});
    const [dimSelectorDisabled, setDimSelectorDisabled] = useState(filtersDisabled);

    const [permission, setPermission] = useState({});

    const globalMin = config.time.global.min;
    const globalMax = config.time.global.max;
    const defaultCutoffDate = config.time.cutoff_date;

    const [startDate, setStartDate] = useState(config.time.reference.min);
    const [endDate, setEndDate] = useState(config.time.reference.max);
    const [cutoffDate, setCutoffDate] = useState(config.time?.cutoff_date);

    const [isFetchingReport, setIsFetchingReport] = useState(fetchReport);

    const { reportId } = useParams();

    useEffect(() => {
        if (reportId && scenarioOptions && templateOptions) {
            getReport(client, reportId)
                .then((report) => {
                    setName(report.name);
                    setTemplate(templateOptions.find((el => el.id === report.report_template_id)));
                    setScenario(scenarioOptions.find((el => el.id === report.scenario_id)));
                    setStartDate(report.start_date);
                    setEndDate(report.end_date);

                    if (isForecastingEnabled(config) && report.cutoff_date) {
                        setCutoffDate(report.cutoff_date);
                    }

                    setMultiplier(report.multiplier);
                    setHideEmptyRows(report.hide_empty_rows);
                    setHideInitialMonths(report.hide_initial_months);
                    populateDimensionSelector(report.report_template_id, report.ui_filters.dimensions_filters_in || [],
                        report.ui_filters.parameters_filters_in, report.ui_filters.dimensions_filters_out || {},
                        report.ui_filters.parameters_filters_out || {});
                    setPermission(report.permission);
                    setIsFetchingReport(false);
                })
                .catch((error) => {
                    setIsFetchingReport(false);
                    notify.error(error, "report.fetch");
                });
        }
    }, [reportId, scenarioOptions, templateOptions]);

    useEffect(() => {
        if (user.permissions?.REPORT?.CREATE) {
            client.report.reportListReportTemplates()
                .then((templates) => {
                    setTemplateOptions(templates);
                })
                .catch((error) => {
                    setTemplateOptions([]);
                    notify.error(error, "report.fetch");
                });
        } else {
            setTemplateOptions([]);
        }

        if (isForecastingEnabled(config) && user.permissions?.FORECAST?.READ) {
            client.scenario.scenarioListScenarios()
                .then((scenarios) => {
                    setScenarioOptions(scenarios.response);
                })
                .catch((error) => {
                    setScenarioOptions([]);
                    notify.error(error, "scenario.fetch");
                });
        } else {
            setScenarioOptions([]);
        }
    }, []);

    const setIntersectingDocumentDimensions = (reportTemplateId) => {
        client.report.reportGetReportTemplateIntersectingDimensions(reportTemplateId)
            .then(dimensions => setDocumentDimensions(dimensions))
            .catch(error => notify.error(error, "report.fetch"));
    };

    const populateDimensionSelector = (reportTemplateId, dimensionsFiltersIn, parametersFiltersIn, dimensionsFiltersOut, parametersFiltersOut) => {
        setIntersectingDocumentDimensions(reportTemplateId);
        setFiltersInDimensions(dimensionsFiltersIn);
        setFiltersInParameters(parametersFiltersIn);
        setFiltersOutDimensions(dimensionsFiltersOut);
        setFiltersOutParameters(parametersFiltersOut);

        const ids = Object.values({ ...parametersFiltersIn, ...parametersFiltersOut }).flat();
        client.dimension.dimensionMetadata(ids)
            .then(metadata => setReportDimensionsMetadata(metadata))
            .catch(error => notify.error(error, "report.fetch"));
    };

    // FIXME: It should keep the common dimensions
    const clearDimensionSelector = () => {
        setFiltersInDimensions([]);
        setFiltersInParameters({});
        setFiltersOutDimensions([]);
        setFiltersOutParameters({});
        setReportDimensionsMetadata({});
    };

    const onFiltersInUpdate = (dimensions, dimParameters, metadata = null) => {
        setFiltersInDimensions(dimensions);
        setFiltersInParameters(dimParameters);
        setReportDimensionsMetadata({
            ...reportDimensionsMetadata,
            ...metadata,
        });
    };

    const onFiltersOutUpdate = (dimensions, dimParameters, metadata = null) => {
        setFiltersOutDimensions(dimensions);
        setFiltersOutParameters(dimParameters);
        setReportDimensionsMetadata({
            ...reportDimensionsMetadata,
            ...metadata,
        });
    };

    const permissionsDisableComponent = () => {
        switch (operation) {
            case "clone":
            case "create":
                return !user.permissions?.REPORT?.CREATE;
            case "edit":
                return !user.permissions?.REPORT?.UPDATE && !permission?.UPDATE;
        }
    };

    // is loading while fetching report, fetching template options, if there's dimension filters but metadata is still fetching, and if scenarios are fetching.
    const isLoading = isFetchingReport || !templateOptions || ((!isEmpty(filtersInParameters) || !isEmpty(filtersOutParameters)) && isEmpty(reportDimensionsMetadata))
        || (isForecastingEnabled(config) && !scenarioOptions);

    const reportParameters = {
        DATE: [startDate, endDate],
    };

    if (scenario) {
        reportParameters.SCENARIO = scenario;
    }

    return (
        <AdvisorContainer title={title} loading={isLoading}>
            <Box
                component="form"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
            >
                <TextField
                    required
                    fullWidth
                    size="small"
                    disabled={permissionsDisableComponent()}
                    data-cy="report_name"
                    error={shouldValidate ? !name : null}
                    helperText={shouldValidate && !name ? config.i18n.warn.field_empty : ""}
                    label={config.i18n.report.list.name_column}
                    value={name}
                    autoComplete="off"
                    onChange={e => setName(e.target.value)}
                    sx={{ flex: 1, mb: 2 }}
                />
                <SelectField
                    key="template-selector"
                    dataCyProp="template_selector"
                    label={config.i18n.report.template}
                    disabled={permissionsDisableComponent()}
                    value={template || ""}
                    possibleValues={templateOptions || []}
                    onChange={(e) => {
                        setTemplate(e);
                        setIntersectingDocumentDimensions(e.id);
                        setDimSelectorDisabled(false);
                        clearDimensionSelector();
                    }}
                    sx={{ flex: 1, mb: 2 }}
                />
                { template
                    ? (
                        <Alert severity="info" sx={{ mb: 2 }} style={{ width: "100%" }}>
                            <Markdown>
                                {template.description}
                            </Markdown>
                        </Alert>
                        )
                    : null}
                <Grid container spacing={2} rowSpacing={2}>
                    <Grid item xs={4}>
                        <ClickableDatePicker
                            required
                            views={["year", "month"]}
                            disabled={permissionsDisableComponent()}
                            label={config.i18n.procurement.project.start_date_column}
                            dataCyProp="startDate_selector"
                            value={startDate || null}
                            onAccept={date => setStartDate(date.startOf("month").startOf("date"))}
                            minDate={globalMin}
                            maxDate={endDate || globalMax}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ClickableDatePicker
                            views={["year", "month"]}
                            disabled={permissionsDisableComponent()}
                            label={config.i18n.procurement.project.end_date_column}
                            dataCyProp="endDate_selector"
                            value={endDate || null}
                            onAccept={date => setEndDate(date.endOf("month").endOf("date"))}
                            minDate={startDate || globalMin}
                            maxDate={globalMax}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ClickableDatePicker
                            dataCyProp="cutoff_date_selector"
                            label={config.i18n.selector.cutoff_date}
                            views={["year", "month"]}
                            disabled={!isForecastingEnabled(config) || !scenario || isEmpty(scenario)}
                            disableFuture
                            minDate={startDate || globalMin}
                            maxDate={defaultCutoffDate}
                            value={cutoffDate}
                            onAccept={date => setCutoffDate(date.startOf("month").startOf("date"))}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            label={config.i18n.selector.scenario}
                            freeSolo
                            forcePopupIcon
                            value={scenario || ""}
                            options={scenarioOptions || []}
                            getOptionLabel={option => option?.name || ""}
                            disabled={!isForecastingEnabled(config) || isEmpty(scenarioOptions) || (permissionsDisableComponent())}
                            size="small"
                            onChange={(_, value) => {
                                setScenario(value || {});

                                if (!value) {
                                    setEndDate(endDate > globalMax ? globalMax : endDate);
                                }
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    autoComplete="off"
                                    label={config.i18n.selector.scenario}
                                    inputProps={{ ...params.inputProps, "readOnly": true, "data-cy": "scenario_autocomplete" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectField
                            label={config.i18n.report.multiplier.label}
                            dataCyProp="multiplier_selector"
                            disabled={permissionsDisableComponent()}
                            value={multiplier}
                            possibleValues={Object.keys(multiplierOptions)}
                            metadata={multiplierOptions}
                            onChange={e => setMultiplier(e)}
                            sx={{ flex: 1 }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    disabled={permissionsDisableComponent()}
                                    data-cy="hide_empty_rows"
                                    checked={hideEmptyRows}
                                    onChange={event => setHideEmptyRows(event.target.checked)}
                                />
                            )}
                            label={config.i18n.selector.hide_rows}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    disabled={permissionsDisableComponent()}
                                    data-cy="hide_initial_months"
                                    checked={hideInitialMonths}
                                    onChange={event => setHideInitialMonths(event.target.checked)}
                                />
                            )}
                            label={config.i18n.selector.hide_initial_months}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DimensionSelector
                            dataCyProp="filters-in-dim-selector"
                            title={config.i18n.customization_bar.filters_in}
                            documentDimensions={documentDimensions}
                            dimensionFilters={filtersInParameters}
                            filtersInParameters={filtersInParameters}
                            dimensions={filtersInDimensions}
                            metadata={reportDimensionsMetadata}
                            parameters={reportParameters}
                            isFiltersIn={true}
                            disabled={dimSelectorDisabled || (permissionsDisableComponent())}
                            onUpdate={(dimension, dimensionFilters = null, metadata = null) => {
                                const newDimensions = union(filtersInDimensions, [dimension]);

                                const newAnalysisDimParameters = { ...filtersInParameters };

                                // remove entry on dimension parameters, if the value is empty.
                                if (dimensionFilters !== null) {
                                    Object.entries(dimensionFilters).forEach(([key, value]) => {
                                        if (isEmpty(value)) {
                                            delete newAnalysisDimParameters[key];
                                        } else {
                                            newAnalysisDimParameters[key] = value;
                                        }
                                    });
                                }

                                // HACK: when a filter is set, existing filters out of that dimension should be removed
                                if (dimension in config.hierarchy && config.hierarchy[dimension].some(key => key in newAnalysisDimParameters)) {
                                    config.hierarchy[dimension].forEach((key) => {
                                        delete filtersOutParameters[key];
                                    });
                                    onFiltersOutUpdate(filtersOutDimensions, filtersOutParameters, metadata);
                                } else if (dimension in newAnalysisDimParameters) {
                                    delete filtersOutParameters[dimension];
                                    onFiltersOutUpdate(filtersOutDimensions, filtersOutParameters, metadata);
                                }

                                onFiltersInUpdate(newDimensions, newAnalysisDimParameters, metadata);
                            }}
                            onDelete={(dimension, dimensionKeys) => {
                                const newDimensions = without(filtersInDimensions, dimension);
                                [dimension, ...dimensionKeys].forEach(key => delete filtersInParameters[key]);

                                onFiltersInUpdate(newDimensions, filtersInParameters);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DimensionSelector
                            dataCyProp="filters-out-dim-selector"
                            title={config.i18n.customization_bar.filters_out}
                            documentDimensions={documentDimensions}
                            dimensionFilters={filtersOutParameters}
                            filtersInParameters={{ ...filtersInParameters, ...filtersOutParameters }}
                            dimensions={filtersOutDimensions}
                            metadata={reportDimensionsMetadata}
                            parameters={reportParameters}
                            isFiltersIn={false}
                            disabled={dimSelectorDisabled || (permissionsDisableComponent())}
                            onUpdate={(dimension, dimensionFilters = null, metadata = null) => {
                                const newDimensions = union(filtersOutDimensions, [dimension]);

                                onFiltersOutUpdate(newDimensions, {
                                    ...filtersOutParameters,
                                    ...dimensionFilters,
                                }, metadata);
                            }}
                            onDelete={(dimension, dimensionKeys) => {
                                const newDimensions = without(filtersOutDimensions, dimension);
                                [dimension, ...dimensionKeys].forEach(key => delete filtersOutParameters[key]);

                                onFiltersOutUpdate(newDimensions, filtersOutParameters);
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                display="flex"
                mt={2}
            >
                <Button
                    variant="contained"
                    color="grey"
                    disabled={loading}
                    sx={{ mr: 1 }}
                    title={config.i18n.button.cancel}
                    component={RouterLink}
                    to="/report"
                >
                    {config.i18n.button.cancel}
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={loading}
                    loadingPosition="start"
                    disabled={permissionsDisableComponent()}
                    data-cy="save"
                    startIcon={icon}
                    title={button}
                    onClick={() => onClick(name, template, scenario, startDate, endDate, cutoffDate, multiplier, hideEmptyRows, hideInitialMonths, filtersInDimensions,
                        filtersInParameters, filtersOutDimensions, filtersOutParameters)}
                >
                    {button}
                </LoadingButton>
            </Box>
        </AdvisorContainer>
    );
};

ReportDetails.propTypes = {
    operation: PropTypes.string,
    title: PropTypes.string,
    button: PropTypes.any,
    icon: PropTypes.any,
    shouldValidate: PropTypes.bool,
    loading: PropTypes.bool,
    fetchReport: PropTypes.bool,
    filtersDisabled: PropTypes.bool,
    onClick: PropTypes.func,
};

export default ReportDetails;
